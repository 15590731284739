<template>
  <div class="w-full flex flex-col p-2 ">
    <div class="w-full justify-between flex border-leadbox-900 border-b-4 p-2">
      <h1 class="text-xl font-semibold">Conversaciones por usuarios</h1>
      <button
        class="text-sm font-medium text-gray-700 cursor-pointer bg-gray-200 rounded-md
        border border-black"
        @click="getConversationsByUsers">
        <RefreshIcon class="m-2 h-5 w-5" aria-hidden="true"/>
      </button>
    </div>
    <div class="p-4">
      <template v-if="isLoading">
        <div class="w-full flex justify-center">
          <img alt="loading" src="@/assets/loading.gif" class="w-20">
        </div>
      </template>
      <template v-else>
        <div class="flex justify-center py-4">
          <div class="w-full md:w-9/12">
            <Bar :data="data" :options="options"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

// import PaginationItem from '@/components/general/PaginationItem.vue';
import {
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export default {
  name: 'BarConversationByUsers',
  displayName: 'BarConversationByUsers',
  components: { RefreshIcon, Bar },
  props: {
    dateValue: Object,
    tab: String,
    type: String,
  },
  data() {
    return {
      connections: [],
      connectionChats: [],
      selectedConnection: null,
      total: 0,
      data: {
        labels: [],
        datasets: [
          {
            label: 'Usuarios',
            backgroundColor: [],
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
      isLoading: false,
      isDetail: false,
      isLoadingExcel: false,
      isLoadingInside: false,
    };
  },
  mounted() {
    if (this.activeCompany) {
      this.getConversationsByUsers();
    }
  },
  watch: {
    tab(value) {
      if (value === 'chat') {
        if (this.activeCompany) {
          this.getConversationsByUsers();
        }
      }
    },
    dateValue(val) {
      console.log('val', val);
      this.getConversationsByUsers();
    },
    type(val) {
      console.log('val', val);
      this.getConversationsByUsers();
    },
    activeCompany(company) {
      if (company) {
        this.getConversationsByUsers();
      }
    },
  },
  methods: {
    getBackgrounds(numColores) {
      const colores = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numColores; i++) {
        const color = this.getRandomColor(); // Genera un color aleatorio
        colores.push(color); // Agrega el color al array
      }
      return colores;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getConversationsByUsers() {
      this.isLoading = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        dateType: this.type,
      };
      this.$store.dispatch('reportsConversationsByUsers', payload)
        .then((response) => {
          console.log('response reportsConversationsByUsers', response);
          this.data.labels = response.data.success.labels;
          this.data.datasets[0].data = response.data.success.data;
          // eslint-disable-next-line max-len
          this.data.datasets[0].backgroundColor = this.getBackgrounds(response.data.success.labels.length);
          this.isLoading = false;
          console.log('respuesta reportsConversationsByUsers', response);
        }, (error) => {
          console.log('error reportsConversationsByUsers', error);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error reportsConversationsByUsers', error);
        });
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>

<style scoped>

</style>
