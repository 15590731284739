import { createStore } from 'vuex';
import axios from '@/http/axios/index';

const userDefaults = {
  active: true,
  avatar: '',
  company_role: 'Superadmin',
  created_at: '',
  email: '',
  fcm_tokens: [],
  first_name: '',
  id: 672,
  is_admin: true,
  is_shareadviser: false,
  is_sharesupervisor: false,
  is_superadmin: false,
  is_supervisor: false,
  is_userwhatsapp: false,
  last_login: '',
  last_name: '',
  name: '',
  phone: '',
  updated_at: '',
  uuid: '',
};

const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {};

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  const userInfo = {};

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key];
  });

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    // eslint-disable-next-line max-len
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key];
  });

  return userInfo;
};
// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
// eslint-disable-next-line camelcase
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export default createStore({
  state: {
    isUserLoggedIn: () => localStorage.getItem('userInfo'),
    is_touch_device: is_touch_device(),
    activeUser: getUserInfo(),
    companies: [],
    activeCompany: null,
    activeForm: null,
    activeMedicalCenter: null,
    activeDoctor: null,
    activeSpecialty: null,
    activeArea: null,
    conversations: [],
    medicalCenters: [],
    doctors: [],
    specialties: [],
    leads: [],
    dates: [],
    current_page: 1,
    from: 1,
    to: 25,
    total: 0,
    next_page_url: null,
    forms: [],
    connections: [],
    appointmentConnections: [],
    events: [],
    current_page_ev: 1,
    from_ev: 1,
    to_ev: 25,
    total_ev: 0,
    next_page_url_ev: null,
    conversationsUsers: [],
    conversationsUsersList: [],
    leadsUsers: [],
    leadsUsersList: [],
    appointmentUsers: [],
    appointmentUsersList: [],
    conversationMessages: [],
    current_page_messages: 1,
    from_messages: 1,
    to_messages: 25,
    total_messages: 0,
    next_page_url_messages: null,
    appointmentNotes: [],
    conversationNotes: [],
    conversationLeads: [],
    conversationAppointments: [],
    appointmentConversations: [],
    appointmentLeads: [],
    appointmentAppointments: {},
    conversationTemplates: [],
    leadLeads: [],
    leadEvents: [],
    leadDocuments: [],
    leadOptins: [],
    leadProducts: [],
    appointmentProducts: [],
    leadConversations: [],
    leadAppointments: [],
    leadHistories: [],
    conversationHistories: [],
    conversationIntegrationWhatsappSends: [],
    conversationRuleHistories: [],
    appointment: [],
    activeConversation: null,
    activeLead: null,
    activeDate: null,
    activeSender: null,
    areas: [],
    locations: [],
    statuses: [],
    conversationStatuses: [],
    appointmentStatuses: [],
    templates: [],
    productCategories: [],
    productSections: [],
    products: [],
    channels: [],
    probabilities: [],
    files: [],
    flows: [],
    leadNotifications: [],
    current_page_ln: 1,
    from_ln: 1,
    to_ln: 25,
    total_ln: 0,
    next_page_url_ln: null,
    messageNotifications: [],
    current_page_mn: 1,
    from_mn: 1,
    to_mn: 25,
    total_mn: 0,
    next_page_url_mn: null,
    companyIntegrations: [],
    integrationUsers: {},
    replies: [],
  },
  getters: {},
  mutations: {
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    },
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || state.activeUser;
      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] !== null) {
          // If some of user property is null - user default property defined in state.activeUser
          state.activeUser[property] = payload[property];
          // Update key in localStorage
          userInfo[property] = payload[property];
        }
      }
      // Store data in localStorage
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    UPDATE_COMPANY_INTEGRATIONS(state, payload) {
      state.companyIntegrations = payload;
    },
    UPDATE_INTEGRATION_USERS(state, payload) {
      state.integrationUsers[payload.type] = payload.users;
    },
    // user
    UPDATE_USER_COMPANIES(state, payload) {
      state.companies = payload;
    },
    UPDATE_COMPANY_AREAS(state, payload) {
      state.areas = payload;
    },
    UPDATE_COMPANY_LOCATIONS(state, payload) {
      state.locations = payload;
    },
    UPDATE_COMPANY_STATUSES(state, payload) {
      state.statuses = payload;
    },
    UPDATE_COMPANY_CONVERSATION_STATUSES(state, payload) {
      state.conversationStatuses = payload;
    },
    UPDATE_COMPANY_APPOINTMENT_STATUSES(state, payload) {
      state.appointmentStatuses = payload;
    },
    UPDATE_COMPANY_CHANNELS(state, payload) {
      state.channels = payload;
    },
    UPDATE_COMPANY_FORMS(state, payload) {
      state.forms = payload;
    },
    UPDATE_COMPANY_MEDICAL_CENTERS(state, payload) {
      state.medicalCenters = payload;
    },
    UPDATE_COMPANY_DOCTORS(state, payload) {
      state.doctors = payload;
    },
    UPDATE_COMPANY_SPECIALTIES(state, payload) {
      state.specialties = payload;
    },
    UPDATE_COMPANY_CONNECTIONS(state, payload) {
      state.connections = payload;
    },
    UPDATE_COMPANY_APPOINTMENT_CONNECTIONS(state, payload) {
      state.appointmentConnections = payload;
    },
    UPDATE_COMPANY_TEMPLATES(state, payload) {
      state.templates = payload;
    },
    UPDATE_COMPANY_REPLIES(state, payload) {
      state.replies = payload;
    },
    UPDATE_COMPANY_FILES(state, payload) {
      state.files = payload;
    },
    UPDATE_COMPANY_FLOWS(state, payload) {
      state.flows = payload;
    },
    UPDATE_COMPANY_PRODUCTS(state, payload) {
      state.products = payload;
    },
    UPDATE_COMPANY_PRODUCT_CATEGORIES(state, payload) {
      state.productCategories = payload;
    },
    UPDATE_COMPANY_PRODUCT_SECTIONS(state, payload) {
      state.productSections = payload;
    },
    UPDATE_ACTIVE_COMPANY(state, payload) {
      state.activeCompany = payload;
    },
    UPDATE_ACTIVE_FORM(state, payload) {
      state.activeForm = payload;
    },
    UPDATE_ACTIVE_MEDICAL_CENTER(state, payload) {
      state.activeMedicalCenter = payload;
    },
    UPDATE_ACTIVE_DOCTOR(state, payload) {
      state.activeDoctor = payload;
    },
    UPDATE_ACTIVE_SPECIALTY(state, payload) {
      state.activeSpecialty = payload;
    },
    UPDATE_ACTIVE_AREA(state, payload) {
      state.activeArea = payload;
    },
    // dates
    UPDATE_USER_DATES(state, payload) {
      state.dates = payload;
    },
    ADD_APPOINTMENTS(state, payload) {
      state.dates = state.dates.concat(payload);
    },
    // leads
    UPDATE_USER_LEADS(state, payload) {
      state.leads = payload;
    },
    ADD_LEADS(state, payload) {
      state.leads = state.leads.concat(payload);
    },
    // conversations
    UPDATE_USER_CONVERSATIONS(state, payload) {
      state.conversations = payload;
    },
    ADD_CONVERSATIONS(state, payload) {
      state.conversations = state.conversations.concat(payload);
    },
    // lead notifications
    UPDATE_USER_LEAD_NOTIFICATIONS(state, payload) {
      state.leadNotifications = payload;
    },
    ADD_LEAD_NOTIFICATIONS(state, payload) {
      state.leadNotifications = state.leadNotifications.concat(payload);
    },
    // message notifications
    UPDATE_USER_MESSAGE_NOTIFICATIONS(state, payload) {
      state.messageNotifications = payload;
    },
    ADD_MESSAGE_NOTIFICATIONS(state, payload) {
      state.messageNotifications = state.messageNotifications.concat(payload);
    },
    ADD_MESSAGE(state, payload) {
      state.conversationMessages = state.conversationMessages.push(payload);
    },
    UPDATE_CURRENT_PAGE(state, payload) {
      state.current_page = payload;
    },
    UPDATE_FROM(state, payload) {
      state.from = payload;
    },
    UPDATE_TO(state, payload) {
      state.to = payload;
    },
    UPDATE_TOTAL(state, payload) {
      state.total = payload;
    },
    UPDATE_NEXT_PAGE_URL(state, payload) {
      state.next_page_url = payload;
    },
    // messages paginate
    ADD_MESSAGES(state, payload) {
      state.conversationMessages = payload.reverse().concat(state.conversationMessages);
    },
    UPDATE_CURRENT_PAGE_MESSAGES(state, payload) {
      state.current_page_messages = payload;
    },
    UPDATE_FROM_MESSAGES(state, payload) {
      state.from_messages = payload;
    },
    UPDATE_TO_MESSAGES(state, payload) {
      state.to_messages = payload;
    },
    UPDATE_TOTAL_MESSAGES(state, payload) {
      state.total_messages = payload;
    },
    UPDATE_NEXT_PAGE_URL_MESSAGES(state, payload) {
      state.next_page_url_messages = payload;
    },
    // leads notifications
    UPDATE_CURRENT_PAGE_LN(state, payload) {
      state.current_page_ln = payload;
    },
    UPDATE_FROM_LN(state, payload) {
      state.from_ln = payload;
    },
    UPDATE_TO_LN(state, payload) {
      state.to_ln = payload;
    },
    UPDATE_TOTAL_LN(state, payload) {
      state.total_ln = payload;
    },
    UPDATE_NEXT_PAGE_URL_LN(state, payload) {
      state.next_page_url_ln = payload;
    },
    // messages notifications
    UPDATE_CURRENT_PAGE_MN(state, payload) {
      state.current_page_mn = payload;
    },
    UPDATE_FROM_MN(state, payload) {
      state.from_mn = payload;
    },
    UPDATE_TO_MN(state, payload) {
      state.to_mn = payload;
    },
    UPDATE_TOTAL_MN(state, payload) {
      state.total_mn = payload;
    },
    UPDATE_NEXT_PAGE_URL_MN(state, payload) {
      state.next_page_url_mn = payload;
    },
    UPDATE_ACTIVE_CONVERSATION(state, payload) {
      state.activeConversation = payload;
    },
    UPDATE_ACTIVE_LEAD(state, payload) {
      state.activeLead = payload;
    },
    UPDATE_LEAD_LEADS(state, payload) {
      state.leadLeads = payload;
    },
    UPDATE_LEAD_EVENTS(state, payload) {
      state.leadEvents = payload;
    },
    UPDATE_USER_EVENTS(state, payload) {
      state.events = payload;
    },
    ADD_USER_EVENTS(state, payload) {
      state.events = state.events.concat(payload);
    },
    // leads notifications
    UPDATE_CURRENT_PAGE_EV(state, payload) {
      state.current_page_ev = payload;
    },
    UPDATE_FROM_EV(state, payload) {
      state.from_ev = payload;
    },
    UPDATE_TO_EV(state, payload) {
      state.to_ev = payload;
    },
    UPDATE_TOTAL_EV(state, payload) {
      state.total_ev = payload;
    },
    UPDATE_NEXT_PAGE_URL_EV(state, payload) {
      state.next_page_url_ev = payload;
    },
    UPDATE_LEAD_DOCUMENTS(state, payload) {
      state.leadDocuments = payload;
    },
    UPDATE_LEAD_OPTINS(state, payload) {
      state.leadOptins = payload;
    },
    UPDATE_LEAD_PRODUCTS(state, payload) {
      state.leadProducts = payload;
    },
    UPDATE_APPOINTMENT_PRODUCTS(state, payload) {
      state.appointmentProducts = payload;
    },
    UPDATE_LEAD_CONVERSATIONS(state, payload) {
      state.leadConversations = payload;
    },
    UPDATE_LEAD_APPOINTMENTS(state, payload) {
      state.leadAppointments = payload;
    },
    UPDATE_LEAD_HISTORY(state, payload) {
      state.leadHistories = payload;
    },
    UPDATE_CONVERSATION_HISTORY(state, payload) {
      state.conversationHistories = payload;
    },
    UPDATE_CONVERSATION_INTEGRATION_WHATSAPP_SENDS(state, payload) {
      state.conversationIntegrationWhatsappSends = payload;
    },
    UPDATE_CONVERSATION_RULE_HISTORY(state, payload) {
      state.conversationRuleHistories = payload;
    },
    UPDATE_APPOINTMENT_HISTORY(state, payload) {
      state.appointmentHistories = payload;
    },
    UPDATE_ACTIVE_SENDER(state, payload) {
      state.activeSender = payload;
    },
    UPDATE_CONVERSATION_MESSAGES(state, payload) {
      state.conversationMessages = payload.reverse();
    },
    UPDATE_CONVERSATION_LEADS(state, payload) {
      state.conversationLeads = payload;
    },
    UPDATE_APPOINTMENT_APPOINTMENTS(state, payload) {
      state.appointmentAppointments = payload;
    },
    UPDATE_APPOINTMENT_LEADS(state, payload) {
      state.appointmentLeads = payload;
    },
    UPDATE_APPOINTMENT_CONVERSATIONS(state, payload) {
      state.appointmentConversations = payload;
    },
    UPDATE_CONVERSATION_APPOINTMENTS(state, payload) {
      state.conversationAppointments = payload;
    },
    UPDATE_CONVERSATION_TEMPLATES(state, payload) {
      state.conversationTemplates = payload;
    },
    UPDATE_CONVERSATION_NOTES(state, payload) {
      state.conversationNotes = payload;
    },
    UPDATE_LEAD_NOTES(state, payload) {
      state.leadNotes = payload;
    },
    UPDATE_APPOINTMENT_NOTES(state, payload) {
      state.appointmentNotes = payload;
    },
    UPDATE_CONVERSATIONS_USERS(state, payload) {
      state.conversationsUsers = payload;
    },
    UPDATE_CONVERSATIONS_USERS_LIST(state, payload) {
      state.conversationsUsersList = payload;
    },
    UPDATE_LEADS_USERS(state, payload) {
      state.leadsUsers = payload;
    },
    UPDATE_LEADS_USERS_LIST(state, payload) {
      state.leadsUsersList = payload;
    },
    UPDATE_APPOINTMENT_USERS(state, payload) {
      state.appointmentUsers = payload;
    },
    UPDATE_APPOINTMENT_USERS_LIST(state, payload) {
      state.appointmentUsersList = payload;
    },
    UPDATE_SALE_PROBABILITIES(state, payload) {
      state.probabilities = payload;
    },
    UPDATE_ACTIVE_DATE(state, payload) {
      state.activeDate = payload;
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', {
          email: payload.email,
          password: payload.password,
        })
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              localStorage.setItem('accessToken', body.token);
              // Update user details
              commit('UPDATE_USER_INFO', body.user, { root: true });
              // Set bearer token in axios
              commit('SET_BEARER', body.token);
              commit('UPDATE_USER_COMPANIES', body.companies.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }));
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'Email o Password incorrecto' });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    setActiveCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.uuid,
          },
        };
        axios.post('/setCompany', {}, config)
          .then((response) => {
            // If there's user data in response
            localStorage.setItem('companyUuid', payload.uuid);
            commit('UPDATE_ACTIVE_COMPANY', payload);
            commit('UPDATE_LEAD_CONVERSATIONS', []);
            commit('UPDATE_LEAD_DOCUMENTS', []);
            commit('UPDATE_LEAD_OPTINS', []);
            commit('UPDATE_LEAD_PRODUCTS', []);
            commit('UPDATE_APPOINTMENT_PRODUCTS', []);
            commit('UPDATE_LEAD_LEADS', []);
            commit('UPDATE_LEAD_EVENTS', []);
            commit('UPDATE_LEAD_NOTES', []);
            commit('UPDATE_LEAD_HISTORY', []);
            commit('UPDATE_CONVERSATION_TEMPLATES', []);
            commit('UPDATE_CONVERSATION_HISTORY', []);
            commit('UPDATE_CONVERSATION_INTEGRATION_WHATSAPP_SENDS', []);
            commit('UPDATE_CONVERSATION_RULE_HISTORY', []);
            commit('UPDATE_CONVERSATION_LEADS', []);
            commit('UPDATE_CONVERSATION_MESSAGES', []);
            commit('UPDATE_CONVERSATION_NOTES', []);
            // console.log('response', response);
            // eslint-disable-next-line no-param-reassign
            response.data.query = '/leads';
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    setActiveForm({ commit }, payload) {
      commit('UPDATE_ACTIVE_FORM', payload);
    },
    // eslint-disable-next-line no-unused-vars
    setActiveMedicalCenter({ commit }, payload) {
      commit('UPDATE_ACTIVE_MEDICAL_CENTER', payload);
    },
    // eslint-disable-next-line no-unused-vars
    setActiveDoctor({ commit }, payload) {
      commit('UPDATE_ACTIVE_DOCTOR', payload);
    },
    // eslint-disable-next-line no-unused-vars
    setActiveSpecialty({ commit }, payload) {
      commit('UPDATE_ACTIVE_SPECIALTY', payload);
    },
    // eslint-disable-next-line no-unused-vars
    setActiveArea({ commit }, payload) {
      commit('UPDATE_ACTIVE_AREA', payload);
    },
    // eslint-disable-next-line no-unused-vars
    tokens({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/user/tokens', {
          tokens: payload,
        })
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendMessage`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendInteractive({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendInteractive`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendMap({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendMap`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createNote({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.post('/conversation/notes', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createLeadNote({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.post('/lead/notes', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createAppointmentNote({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.appointment,
          },
        };
        axios.post('/appointment/notes', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.post(`/lead/document/${payload.document}/sendEmail`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteFile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
            Company: payload.company,
          },
        };
        // console.log('config', config);
        axios.delete(`/files/${payload.document}`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createLead({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/createLead', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    assignAuto({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/lead/assignAuto', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    assignAutoConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversation/assignAuto', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    assignOne({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/lead/assign', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    assignOneConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversation/assign', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendProduct`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendFile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendFile`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendFlow({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendFlow`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendGif({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendGif`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateSessions({ commit }, payload) {
      const config = {
        headers: {
          Company: payload.company,
          Appointment: payload.appointment,
        },
      };
      return new Promise((resolve, reject) => {
        axios.post('/appointment/updateSessions', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateProductNote({ commit }, payload) {
      const config = {
        headers: {
          Company: payload.company,
          Appointment: payload.appointment,
        },
      };
      return new Promise((resolve, reject) => {
        axios.post('/appointment/updateProductNote', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    addProducts({ commit }, payload) {
      const config = {
        headers: {
          Company: payload.company,
          Appointment: payload.appointment,
        },
      };
      return new Promise((resolve, reject) => {
        axios.post('/appointment/addProducts', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteProducts({ commit }, payload) {
      const config = {
        headers: {
          Company: payload.company,
          Appointment: payload.appointment,
        },
      };
      return new Promise((resolve, reject) => {
        axios.post('/appointment/deleteProducts', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reScheduleDate({ commit }, payload) {
      const config = {
        headers: {
          Company: payload.company,
          Appointment: payload.appointment,
        },
      };
      return new Promise((resolve, reject) => {
        axios.post('/appointment/reSchedule', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendTemplate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/conversations/${payload.id}/sendTemplate`, payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createConversation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversations', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
          timeout: 0,
        };
        axios.post('/conversations-massive', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createConversationsAudience({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
          timeout: 0,
        };
        axios.post('/conversations-audience', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    companies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('user/companies', payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const companies = response.data.success;
              commit('UPDATE_USER_COMPANIES', companies.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              }));
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    numbers({ commit }, company) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: company,
          },
        };
        axios.get('/conversations/numbers', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              // const body = response.data.success;
              // commit('UPDATE_USER_CONVERSATIONS', body.conversations);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversationsUsers', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_CONVERSATIONS_USERS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationUsersList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversationsUsersList', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_CONVERSATIONS_USERS_LIST', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    areas({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/areas', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_AREAS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    locations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/locations', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_LOCATIONS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    statuses({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leadStatuses', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_STATUSES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationStatuses({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversationStatuses', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_CONVERSATION_STATUSES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentStatuses({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/appointmentStatuses', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_APPOINTMENT_STATUSES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    channels({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/channels', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_CHANNELS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    forms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/forms', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_FORMS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    medicalCenters({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/medical_centers', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_MEDICAL_CENTERS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    doctors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/doctors', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_DOCTORS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    specialties({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/specialties', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_SPECIALTIES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    connections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/connections', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_CONNECTIONS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    createAppointment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/${payload.endpoint}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentConnections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/appointment-connections', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_APPOINTMENT_CONNECTIONS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsCities({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/cities`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsMedicalCenters({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/medical-centers`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsDoctors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/doctors`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsSpecialties({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/specialties`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsSpecialtiesByDoctor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/specialties-by-doctor`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsSectors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/sectors`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsSectorMedicalCenters({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/sector-medical-centers`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    appointmentsSchedules({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`https://leadbox.ec/api/callback/leadbox-appointments/${payload.connection}/${payload.endpoint}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    fullEvents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/events-full', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    events({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/events', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              const { data } = response.data.success;
              commit('UPDATE_USER_EVENTS', data);
              const { from } = response.data.success;
              commit('UPDATE_FROM_EV', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_EV', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_EV', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_EV', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageEvents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              const { data } = response.data.success;
              commit('ADD_USER_EVENTS', data);
              const { from } = response.data.success;
              commit('UPDATE_FROM_EV', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_EV', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_EV', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_EV', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    probabilities({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/saleProbabilities', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_SALE_PROBABILITIES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    templates({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/templates', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_TEMPLATES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    replies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/replies-grouped', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_REPLIES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    files({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/files', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_FILES', response.data.success.data);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    flows({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/flows', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_FLOWS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    products({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/products', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_PRODUCTS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    productCategories({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/product-categories', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_PRODUCT_CATEGORIES', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    productSections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/product-sections', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_COMPANY_PRODUCT_SECTIONS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leadsUsers', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_LEADS_USERS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/appointmentsUsers', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_APPOINTMENT_USERS', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    companyUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/company/users', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    companyForms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/company/forms', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    companyStatuses({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/company/statuses', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadUsersList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leadsUsersList', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_LEADS_USERS_LIST', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentUsersList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/appointmentsUsersList', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              commit('UPDATE_APPOINTMENT_USERS_LIST', response.data.success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversations({ commit }, payload) {
      // console.log('pasy', payload);
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`conversations/${payload.type}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('UPDATE_USER_CONVERSATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    clearConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit('UPDATE_USER_CONVERSATIONS', []);
          commit('UPDATE_CURRENT_PAGE', 1);
          commit('UPDATE_FROM', 1);
          commit('UPDATE_TO', 25);
          commit('UPDATE_TOTAL', 0);
          commit('UPDATE_NEXT_PAGE_URL', null);
          commit('UPDATE_ACTIVE_CONVERSATION', null);
          resolve(true);
        } catch (e) {
          // console.log(e);
          reject(e);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    contacts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('contacts', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              console.log('responde contacts', data);
              // commit('UPDATE_USER_DATES', data);
              // // eslint-disable-next-line camelcase
              // const { current_page } = response.data.success;
              // commit('UPDATE_CURRENT_PAGE', current_page);
              // const { from } = response.data.success;
              // commit('UPDATE_FROM', from);
              // const { to } = response.data.success;
              // commit('UPDATE_TO', to);
              // const { total } = response.data.success;
              // commit('UPDATE_TOTAL', total);
              // // eslint-disable-next-line camelcase
              // const { next_page_url } = response.data.success;
              // commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    dates({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`appointments/${payload.type}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('UPDATE_USER_DATES', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`leads/${payload.type}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('UPDATE_USER_LEADS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    clearLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit('UPDATE_USER_LEADS', []);
          // eslint-disable-next-line camelcase
          commit('UPDATE_CURRENT_PAGE', 1);
          commit('UPDATE_FROM', 1);
          commit('UPDATE_TO', 25);
          commit('UPDATE_TOTAL', 0);
          commit('UPDATE_NEXT_PAGE_URL', null);
          commit('UPDATE_ACTIVE_LEAD', null);
          resolve(true);
        } catch (e) {
          // console.log(e);
          reject(e);
        }
      });
    },
    integrations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('integrations', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              const { success } = response.data;
              commit('UPDATE_COMPANY_INTEGRATIONS', success);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    integrationUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`integration-${payload.type}/${payload.integration}/users`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              const { success } = response.data;
              commit('UPDATE_INTEGRATION_USERS', { users: success, type: payload.type });
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadNotifications({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('lead-notifications', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('UPDATE_USER_LEAD_NOTIFICATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_LN', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_LN', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_LN', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_LN', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_LN', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    messageNotifications({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('message-notifications', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('UPDATE_USER_MESSAGE_NOTIFICATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_MN', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_MN', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_MN', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_MN', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_MN', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageAppointments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('ADD_APPOINTMENTS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('ADD_CONVERSATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('ADD_LEADS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM', from);
              const { to } = response.data.success;
              commit('UPDATE_TO', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageLeadNotifications({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(payload.url, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('ADD_LEAD_NOTIFICATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_LN', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_LN', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_LN', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_LN', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_LN', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageMessageNotifications({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(payload.url, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              commit('ADD_MESSAGE_NOTIFICATIONS', data);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_MN', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_MN', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_MN', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_MN', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_MN', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    updateConversation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.put(`/conversations/${payload.conversation.uuid}`, payload.conversation, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set active conversation
              const body = response.data.success;
              commit('UPDATE_ACTIVE_CONVERSATION', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    updateLead({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.put(`/leads/${payload.lead.uuid}`, payload.lead, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set active conversation
              const body = response.data.success;
              commit('UPDATE_ACTIVE_LEAD', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    updateAppointment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.put(`/appointments/${payload.lead.uuid}`, payload.lead, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set active conversation
              const body = response.data.success;
              commit('UPDATE_ACTIVE_DATE', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leads/delete_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteDates({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/appointments/delete_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    archiveLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leads/archive_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    activeLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/leads/active_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversations/delete_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    archiveConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/conversations/archive_these', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    changeStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`/lead/${payload.leadId}/status`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log('error', error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    changeAppointmentStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`/appointment/${payload.appointmentId}/status`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log('error', error);
            reject(error);
          });
      });
    },
    updateSender({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.put(`/senders/${payload.sender.uuid}`, payload.sender, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set active conversation
              const body = response.data.success;
              commit('UPDATE_ACTIVE_SENDER', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`/conversations/${payload.uuid}`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              body.fields = JSON.parse(body.fields);
              commit('UPDATE_ACTIVE_CONVERSATION', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    lead({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`/leads/${payload.uuid}`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              if (body.contact) {
                body.contact.leads = body.contact.leads.reverse();
              }
              commit('UPDATE_ACTIVE_LEAD', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    date({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`/appointments/${payload.uuid}`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_ACTIVE_DATE', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // conversations elements
    sender({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get(`/senders/${payload.uuid}`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_ACTIVE_SENDER', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    messageMedia({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
            Company: payload.company,
          },
        };
        axios.get(`/messageMedias/${payload.uuid}/download`, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    messages({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/messages', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.map((msg) => {
                const mensaje = msg;
                if (msg.interactive) {
                  mensaje.interactive = JSON.parse(msg.interactive);
                }
                if (msg.order) {
                  mensaje.order = JSON.parse(msg.order);
                }
                return mensaje;
              });
              commit('UPDATE_CONVERSATION_MESSAGES', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    messagesPaginated({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/messages-paginated', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.data.map((msg) => {
                const mensaje = msg;
                if (msg.interactive) {
                  mensaje.interactive = JSON.parse(msg.interactive);
                }
                if (msg.order) {
                  mensaje.order = JSON.parse(msg.order);
                }
                return mensaje;
              });
              commit('UPDATE_CONVERSATION_MESSAGES', body);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_MESSAGES', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_MESSAGES', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_MESSAGES', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_MESSAGES', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_MESSAGES', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    nextPageMessagesPaginated({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get(payload.url, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const { data } = response.data.success;
              const body = data.map((msg) => {
                const mensaje = msg;
                if (msg.interactive) {
                  mensaje.interactive = JSON.parse(msg.interactive);
                }
                if (msg.order) {
                  mensaje.order = JSON.parse(msg.order);
                }
                return mensaje;
              });
              commit('ADD_MESSAGES', body);
              // eslint-disable-next-line camelcase
              const { current_page } = response.data.success;
              commit('UPDATE_CURRENT_PAGE_MESSAGES', current_page);
              const { from } = response.data.success;
              commit('UPDATE_FROM_MESSAGES', from);
              const { to } = response.data.success;
              commit('UPDATE_TO_MESSAGES', to);
              const { total } = response.data.success;
              commit('UPDATE_TOTAL_MESSAGES', total);
              // eslint-disable-next-line camelcase
              const { next_page_url } = response.data.success;
              commit('UPDATE_NEXT_PAGE_URL_MESSAGES', next_page_url);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/leads', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response de conversation leads', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_CONVERSATION_LEADS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationAppointments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/appointments', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_CONVERSATION_APPOINTMENTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.date,
          },
        };
        axios.get('/appointment/conversations', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_APPOINTMENT_CONVERSATIONS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.date,
          },
        };
        axios.get('/appointment/leads', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_APPOINTMENT_LEADS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentAppointments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.date,
          },
        };
        axios.get('/appointment/appointments', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              console.log('bosy', body);
              commit('UPDATE_APPOINTMENT_APPOINTMENTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationTemplates({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/templates', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.waba_templates;
              commit('UPDATE_CONVERSATION_TEMPLATES', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    connectionTemplates({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.connection,
          },
        };
        axios.get('/connection/templates', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    integrationCampaigns({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.get('/integration_campaigns', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    integrationCampaignFiles({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
            Campaign: payload.campaign,
          },
        };
        axios.get('/integration_campaign_files', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line
    medicSearch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('https://api.hospitalmetropolitano.org/v1/directorio-medico', payload)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.status) {
              // Set accessToken
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationNotes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/notes', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_CONVERSATION_NOTES', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // leads elements
    leadNotes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/notes', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_NOTES', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentNotes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.appointment,
          },
        };
        axios.get('/appointment/notes', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_APPOINTMENT_NOTES', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadEvents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/events', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_EVENTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadConversations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/conversations', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_CONVERSATIONS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadAppointments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/contact-appointments', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_APPOINTMENTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadDocuments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/documents', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_DOCUMENTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadOptins({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/optins', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_OPTINS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadProducts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/products', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_PRODUCTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentProducts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.appointment,
          },
        };
        axios.get('/appointment/products', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_APPOINTMENT_PRODUCTS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadHistory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/history', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_LEAD_HISTORY', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationHistory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/history', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_CONVERSATION_HISTORY', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationIntegrationWhatsappSends({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/integration-whatsapp-sends', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_CONVERSATION_INTEGRATION_WHATSAPP_SENDS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    conversationRuleHistory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Conversation: payload.conversation,
          },
        };
        axios.get('/conversation/rule-history', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_CONVERSATION_RULE_HISTORY', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    appointmentHistory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Appointment: payload.appointment,
          },
        };
        axios.get('/appointment/history', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_APPOINTMENT_HISTORY', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/leads', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success;
              commit('UPDATE_LEAD_LEADS', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    leadOrders({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Lead: payload.lead,
          },
        };
        axios.get('/lead/orders', config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              // Set accessToken
              const body = response.data.success.reverse();
              commit('UPDATE_LEAD_HISTORY', body);
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response.data });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },

    setActiveConversation({ commit }, conversation) {
      commit('UPDATE_ACTIVE_CONVERSATION', conversation);
      if (conversation === null) {
        commit('UPDATE_CURRENT_PAGE_MESSAGES', 1);
        commit('UPDATE_FROM_MESSAGES', 1);
        commit('UPDATE_TO_MESSAGES', 25);
        commit('UPDATE_TOTAL_MESSAGES', 0);
        commit('UPDATE_NEXT_PAGE_URL_MESSAGES', null);
      }
    },
    setActiveDate({ commit }, date) {
      commit('UPDATE_ACTIVE_DATE', date);
    },
    setActiveLead({ commit }, lead) {
      commit('UPDATE_ACTIVE_LEAD', lead);
      if (lead === null) {
        commit('UPDATE_LEAD_CONVERSATIONS', []);
        commit('UPDATE_LEAD_DOCUMENTS', []);
        commit('UPDATE_LEAD_OPTINS', []);
        commit('UPDATE_LEAD_PRODUCTS', []);
        commit('UPDATE_LEAD_LEADS', []);
        commit('UPDATE_LEAD_EVENTS', []);
        commit('UPDATE_LEAD_NOTES', []);
        commit('UPDATE_LEAD_HISTORY', []);
      }
    },
    setToken({ commit }) {
      const token = localStorage.getItem('accessToken');
      if (token) {
        commit('SET_BEARER', token);
      }
    },
    fetchAccessToken() {
      return new Promise((resolve) => {
        axios.post('/api/auth/refresh-token', { accessToken: localStorage.getItem('accessToKen') })
          .then((response) => {
            resolve(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsLeadsByChannels({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/leadsByChannels', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsLeadsByChannelsDownload({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/leadsByChannelsDownload', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsLeadsByForms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/leadsByForms', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsLeadsByFormsDownload({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/leadsByFormsDownload', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByConnectionPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByConnection({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`/reports/conversationsByConnection/${payload.connection}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByConnections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByConnections', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByConnectionsDownloads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByConnectionsDownloads', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByHasLeads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByHasLead', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByHasAppointments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByHasAppointments', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByCities({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByCities', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsConversationsByUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/conversationsByUsers', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // appointments reports
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByConnectionPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(payload.url, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByConnection({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post(`/reports/appointmentsByConnection/${payload.connection}`, payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByConnections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/appointmentsByConnections', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByConnectionsDownloads({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/appointmentsByConnectionsDownloads', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByMedicalCenters({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/appointmentsByMedicalCenters', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByUsers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/appointmentsByUsers', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    reportsAppointmentsByDoctors({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Company: payload.company,
          },
        };
        axios.post('/reports/appointmentsByDoctors', payload, config)
          .then((response) => {
            // If there's user data in response
            // console.log('response', response);
            if (response.data.success) {
              resolve(response);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ error: response });
            }
          })
          .catch((error) => {
            // console.log(error);
            reject(error);
          });
      });
    },
  },
  modules: {},
});
