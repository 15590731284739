<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            {{ activeUser.name }}, vamos por más
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'leads'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'leads'" @changed-company="changedCompany"/>
        <FormDropDown class="p-1" :type="'leads'" @changed-form="changedForm"/>
        <AreaDropDown v-if="areas.length > 0"
                      class="p-1" :type="'leads'" @changed-form="changedArea"/>
        <LeadNotificationsDropDown class="p-1" @open-lead="getLead"/>
        <MessageNotificationsDropDown class="p-1" @open-conversation="getConversation"/>
        <EventsDropDown class="p-1"  @open-lead="getLead"/>

        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>
      <div class="flex w-full flex-grow h-80">
        <!--left menu-->
        <div class="h-max bg-header lg:flex flex-col sidebar-menu md:overflow-y-auto"
             v-bind:class="{ 'hidden' : activeLead, 'flex' : !activeLead }">
          <div class="py-1 flex w-full cursor-pointer">
            <button class="sidebar-icons" @click="isShow = !isShow">
              <MenuIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
            </button>
          </div>
          <div class="test-sidebar" v-bind:class="{ hidden: !isShow }">
            <vue-collapsible-panel-group accordion base-color="#000000">
              <vue-collapsible-panel>
                <template #title>
                  <div class="py-1 flex cursor-pointer md:w-52 w-32" @click="setType('active')"
                       @keydown="setType('active')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'active' }">
                    <CollectionIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Activos</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in activeStatuses"
                                v-bind:key="status.id" :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('closed')"
                       @keydown="setType('closed')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'closed' }">
                    <LockClosedIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Cerrados</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in closedStatuses" v-bind:key="status.id"
                                :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('archived')"
                       @keydown="setType('archived')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'archived' }">
                    <ArchiveIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Archivados</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in statuses" v-bind:key="status.id" :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('transferred')"
                       @keydown="setType('transferred')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'transferred' }">
                    <SwitchHorizontalIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Transferidos
                    </div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in statuses" v-bind:key="status.id" :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
            </vue-collapsible-panel-group>
            <div class="h-fit max-h-fit">
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="true">
                  <template #title>
                    <div class="py-1 flex">
                      <UserIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                        <b>ASESORES</b>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div class="overflow-auto h-fit">
                      <template v-if="isLoadingLeadsUsers">
                        <p class="text-white text-md">Cargando...</p>
                      </template>
                      <template v-else>
                        <AsesorItem v-for="(user) in leadsUsers" v-bind:key="user.id" :user="user"
                                    :selected="selectedUser" @set-user="setUser"/>
                      </template>
                    </div>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
        </div>
        <!--intermediate menu-->
        <div class="w-full md:w-7/12 lg:w-1/5 h-max bg-gray-200 lg:flex flex-col"
             v-bind:class="{ 'hidden' : activeLead, 'flex' : !activeLead }">
          <div class="w-full flex justify-center">
            <div class="flex py-1 mx-1 w-full">
              <litepie-datepicker class="filter-date" placeholder="Ver por fecha"
                                  separator=" hasta " i18n="es" overlay :auto-apply="false"
                                  :formatter="formatter" :options="options" v-model="dateValue">
              </litepie-datepicker>
            </div>
          </div>
          <div class="w-full">
            <div class="flex rounded-lg bg-gray-100">
              <!--eslint-disable-next-line-->
              <input class="p-2 w-10/12 bg-gray-100 text-sm focus:outline-none" v-model="search"
                     type="text" placeholder="Buscar leads...">
              <div class="p-1 w-2/12 flex justify-center items-center cursor-pointer bg-gray-100"
                   @click="getLeads()" @keydown="getLeads()">
                <SearchIcon class="m-2 h-6 w-6" aria-hidden="true"/>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="m-1 flex justify-between rounded-lg">
              <p class="my-2"><b>Total:</b> {{ total }}</p>
              <div class="flex">
                <div title="Eliminar selecionados" class="hover:bg-gray-300">
                  <TrashIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             v-if="selectedLeads.length > 0 &&
                             ( activeUser.is_admin || activeUser.is_superadmin )"
                             @click="showDeleteModal = true;"/>
                </div>
                <div title="Archivar selecionados" class="hover:bg-gray-300">
                  <ArchiveIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                               v-if="selectedLeads.length > 0 && type !== 'archived'"
                               @click="showArchiveModal = true;"/>
                </div>
                <div title="Activar selecionados" class="hover:bg-gray-300"
                     v-if="type === 'archived'">
                  <CheckIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             v-if="selectedLeads.length > 0" @click="showActiveModal = true;"/>
                </div>
                <div title="Asignar selecionados" class="hover:bg-gray-300">
                  <UserIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                            v-if="selectedLeads.length > 0" @click="showAssignOneModal = true;"/>
                </div>
                <!--                <div title="Filtro por premiados" class="hover:bg-gray-300">-->
                <!--                  <AwardedDropDown :awarded="awarded"-->
                <!--                                   @set-awarded="setAwarded"/>-->
                <!--                </div>-->
                <div title="Asignación automática" class="hover:bg-gray-300">
                  <UsersIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             @click="showAssignModal = true;"/>
                </div>
                <div title="Filtro de ubicaciones" class="hover:bg-gray-300">
                  <LocationsDropDown v-if="locations.length>0" class="w-7 m-1 cursor-pointer"
                                     aria-hidden="true" :location="location"
                                     @set-location="setLocation"/>
                </div>
                <div title="Filtro de canales" class="hover:bg-gray-300">
                  <ChannelsDropDown v-if="channels.length>0" class="w-7 m-1 cursor-pointer"
                                    aria-hidden="true" :channel="channel"
                                    @set-channel="setChannel"/>
                </div>
                <div title="Filtro de probabilidad" class="hover:bg-gray-300">
                  <ProbabilitiesDropDown v-if="probabilities.length>0"
                                         class="w-7 m-1 cursor-pointer"
                                         aria-hidden="true" :prob="probability"
                                         @set-probability="setProbability"/>
                </div>
                <div title="Opciones" class="hover:bg-gray-300">
                  <LeadOptionsDropDown :order="order" @select-all="selectAll" @refresh="refresh"
                                       :all="allSelected" @change-order="changeOrder"
                                       @open-create="showCreateModal = true;"
                                       @open-group="showCreateModal = true;"/>
                </div>
              </div>

            </div>
          </div>
          <div class="scroll-smooth overflow-auto h-fit">
            <template v-if="isLoadingLeads">
              <p class="text-md text-black text-center">Cargando....</p>
            </template>
            <template v-else>
              <template v-if="leads.length>0">
                <LeadItem v-for="(lead) in leads"
                          v-bind:key="lead.id" :lead="lead"
                          :selected="selectedLeads"
                          @check-clicked="selectGroupLead"
                          @selected-lead="selectLead"/>
              </template>
              <template v-else>
                <p class="text-center mt-10">
                  {{
                    search !== null && search !== '' ?
                      'No hay resultados para ' + this.search :
                      'No hay leads'
                  }}</p>
              </template>
            </template>
          </div>
          <div class="w-full" v-if="nextPageUrl">
            <div class="m-1 flex rounded-lg cursor-pointer bg-white place-content-center p-2"
                 @click="loadMore()" @keydown="loadMore()">
              {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
            </div>
          </div>
          <!--asignar leads-->
          <ModalBox :showing="showAssignModal" @close="showAssignModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar leads</b></p>
            </div>
            <template v-if="isLoadingAssignAuto">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex">
                <p class="text-black">
                  Entregar los leads sin asignar automaticamente a los asesores
                </p>
              </div>
              <div class="w-full flex">
                <div class="m-1">
                  <Toggle v-model="selectAssignUsers"/>
                </div>
                <p class="ml-1">Seleccionar Usuarios?</p>
              </div>
              <template v-if="selectAssignUsers">
                <div class="w-full flex" v-for="(user) in assignUsersList"
                     v-bind:key="user.id">
                  <div class="m-1">
                    <Toggle v-model="user.selected"/>
                  </div>
                  <p class="ml-1">{{ user.name }}</p>
                </div>
              </template>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignLeads()">Asignar equitativamente
                </button>
              </div>
            </template>
          </ModalBox>
          <!--asignar un asesor leads-->
          <ModalBox :showing="showAssignOneModal" @close="showAssignOneModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar leads a asesor</b></p>
            </div>
            <template v-if="isLoadingAssign">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex flex-col">
                <!--eslint-disable-next-line-->
                <label class="w-full text-left" for="assignUser">
                  <b>Usuario:</b>
                </label>
                <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="leadsUsersList.length < 1"
                        id="assignUser" v-model="assignUserModal" name="assignUser">
                  <option :value="null" class="px-3 py-2 text-sm">
                    -- Selecciona un asesor --
                  </option>
                  <option v-for="(usr) in leadsUsersList"
                          v-bind:key="usr.id" :value="usr"
                          class="px-3 py-2 text-sm">
                    {{ usr.name }}
                  </option>
                </select>
              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignOneModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignOneLeads()">Asignar
                </button>
              </div>
            </template>
          </ModalBox>
          <!--elimnar leads-->
          <ModalBox :showing="showDeleteModal" @close="showDeleteModal = false">
            <div class="flex">
              <p class="text-black"><b>Eliminar leads</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas eliminar {{ selectedLeads.length }} leads seleccionados?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showDeleteModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="deleteLeads()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--archivar leads-->
          <ModalBox :showing="showArchiveModal" @close="showArchiveModal = false">
            <div class="flex">
              <p class="text-black"><b>Archivar leads</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas archivar {{ selectedLeads.length }} leads seleccionados?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showArchiveModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="archiveLeads()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--activar leads-->
          <ModalBox :showing="showActiveModal" @close="showActiveModal = false">
            <div class="flex">
              <p class="text-black"><b>Activar leads</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas activar {{ selectedLeads.length }} leads seleccionados?
              </p>
            </div>
            <div class="flex flex-col">
              <!--eslint-disable-next-line-->
              <label class="w-full text-left" for="activeStatus">
                <b>Status:</b>
              </label>
              <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="statuses.length < 1"
                      id="activeStatus" v-model="activeStatusModal" name="assignUser">
                <option :value="null" class="px-3 py-2 text-sm">
                  -- se mantiene el status de los leads --
                </option>
                <option v-for="(status) in statuses"
                        v-bind:key="status.id" :value="status"
                        class="px-3 py-2 text-sm">
                  {{ status.name }}
                </option>
              </select>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showActiveModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="activeLeads()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--crear lead-->
          <ModalBox :showing="showCreateModal" @close="showCreateModal = false">
            <div class="flex">
              <p class="text-black"><b>Crear lead</b></p>
            </div>
            <div class="justify-start p-4 w-full">
              <template v-if="isLoadingCreateLead">
                <p class="text-center">Cargando...</p>
              </template>
              <template v-else>
                <p class="my-1">formulario:</p>
                <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                <select v-model="newLead.form" @change="setFields()"
                        class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                  <option disabled :value="null">-- Seleccione un formulario --</option>
                  <option v-for="(form) in forms" v-bind:key="form.id" :value="form">
                    {{ form.name }}
                  </option>
                </select>
                <template v-if="newLead.form">
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Nombre:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.name" type="text">
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Email:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.email" type="text">
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Teléfono:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.phone" type="tel">
                    </div>
                  </div>
                  <template v-if="newLead.fields.length>0">
                    <div class="flex" v-for="(field, key) in newLead.fields" v-bind:key="key">
                      <div class="w-1/2 p-2">
                        <b>{{ field.name ? field.name : field.label }}:</b>
                      </div>
                      <div class="w-1/2">
                        <template v-if="field.type === 'TEXT' ||field.type === null
                        ||field.type === undefined">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="text">
                        </template>
                        <template v-if="field.type === 'EMAIL'">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="email">
                        </template>
                        <template v-if="field.type === 'PHONE'">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="tel">
                        </template>
                        <template v-if="field.type === 'SELECT'">
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data">
                            <option :value="null">--Seleccione una opción--</option>
                            <option v-for=" (option, ky) in field.options"
                                    :value="option" v-bind:key="ky">
                              {{ option }}
                            </option>
                          </select>
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-if="showCreateFieldInterface">
                    <div class="form-group flex my-1">
                      <div class="w-1/2 flex flex-col">
                        <!--eslint-disable-next-line-->
                        <label for="fieldTitle"><b>Título:</b></label>
                        <input type="text" v-model="createNewField.name" id="createFieldTitle"
                               class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                      </div>
                      <div class="w-1/2 flex flex-col">
                        <!--eslint-disable-next-line-->
                        <label for="fieldData"><b>Valor:</b></label>
                        <input type="text" v-model="createNewField.data" id="createFieldData"
                               class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                      </div>
                    </div>
                    <div class="flex my-1">
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                              @click="showCreateFieldInterface = false;
                                    createNewField = {name:null, label: null, data:null,};"
                              type="button">Cancelar
                      </button>
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                              @click="addNewField()">Aceptar
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex justify-end my-2">
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                              @click="showCreateFieldInterface = true;">Agregar campo
                      </button>
                    </div>
                  </template>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newLead.addProduct"/>
                    </div>
                    <p class="ml-1">Agregar producto?</p>
                  </div>
                  <template v-if="newLead.addProduct">
                    <div>
                      <!--eslint-disable-next-line-->
                      <label class="w-full text-left" for="newLeadCategory">
                        <b>Categoría:</b>
                      </label>
                      <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                              :disabled="companyCategories.length < 1"
                              id="newLeadCategory" v-model="newLead.category"
                              name="leadCategory">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona categoría --
                        </option>
                        <option v-for="(category) in companyCategories"
                                v-bind:key="category.id" :value="category"
                                class="px-3 py-2 text-sm">
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <template v-if="newLead.category">
                        <!--eslint-disable-next-line-->
                        <label class="w-full text-left" for="leadProduct">
                          <b>Producto:</b>
                        </label>
                        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="newLead.category.products.length < 1"
                                id="leadProduct" v-model="newLead.product" name="leadProduct">
                          <option :value="null" class="px-3 py-2 text-sm">
                            -- Selecciona uno --
                          </option>
                          <option v-for="(prod) in newLead.category.products"
                                  v-bind:key="prod.id" :value="prod"
                                  class="px-3 py-2 text-sm">
                            {{ prod.name }}
                          </option>
                        </select>
                      </template>
                    </div>
                  </template>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newLead.addUser"/>
                    </div>
                    <p class="ml-1">Asignar usuario?</p>
                  </div>
                  <template v-if="newLead.addUser">
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="leadUser">
                      <b>Usuario:</b>
                    </label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="leadsUsersList.length < 1"
                            id="leadUser" v-model="newLead.user" name="leadUser">
                      <option :value="null" class="px-3 py-2 text-sm">
                        -- Selecciona un asesor --
                      </option>
                      <option v-for="(usr) in leadsUsersList"
                              v-bind:key="usr.id" :value="usr"
                              class="px-3 py-2 text-sm">
                        {{ usr.name }}
                      </option>
                    </select>
                  </template>
                </template>
              </template>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showCreateModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="createLead()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--loading-->
          <LoadingBox :showing="isLoadingLead">
            <div class="flex flex-col">
              <img alt="loading" src="@/assets/loading.gif" class="w-20 mx-3">
              <p class="text-center text-white">
                {{ activeUser.name }},
              </p>
              <p class="text-center text-white">
                vamos por más...
              </p>
            </div>
          </LoadingBox>
        </div>
        <!--active lead-->
        <div class="lg:w-3/5 w-full flex-grow h-max flex flex-col  bg-white drop-shadow-2xl
        shadow-2xl" v-bind:class="{ 'flex' : activeLead, 'hidden' : !activeLead }">
          <template v-if="isLoadingLead">
            <p class="text-center my-10">
              {{ activeUser.name }}, vamos por más...
            </p>
          </template>
          <template v-else>
            <template v-if="activeLead">
              <!--top bar -->
              <div class="w-full  flex flex-col bg-gray-100">
                <div class="w-full hidden md:flex ">
                  <div class=" w-full md:w-5/12 flex ">
<!--                    <div class="p-1 content-center relative">
                      <div class="rounded-full absolute left-1 top-6 text-white p-2
                      text-xs h-2 w-2" v-if="activeLead.status"
                           :style="{ backgroundColor: activeLead.status.color}">
                      </div>
                      <img class="w-12 h-12 my-6 rounded-full self-center border-2 border-gray-300"
                           src="@/assets/default-profile.svg"
                           alt="user">

                    </div>-->
                    <div class="p-1 flex flex-col">
                      <div class="flex text-sm">
                        <!--eslint-disable-next-line-->
                        <input v-if="editName" class="border-1 p-1" v-model="activeLead.name"
                               type="text">
                        <b class="p-1 " v-else>{{ activeLead.name }}</b>
                        <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                       @click="editName = !editName" aria-hidden="true"/>
                      </div>
                      <div class="flex my-1 mt-0 content-center">
                        <img class="w-8 h-8 rounded-lg" :src="source" alt="user">
                        <div class="flex-grow ">
                          <div class="text-md m-2 my-1 self-center">
                            {{ activeLead.form_name }}
                          </div>
                        </div>
                      </div>
                      <div class="flex text-sm justify-between m-1">
                        <div>{{ activeLead.created_at.formated_date }}</div>
                      </div>
                      <div class="acor">
                        <vue-collapsible-panel-group base-color="#ffffff">
                          <vue-collapsible-panel :expanded="false">
                            <template #title>
                              Datos de Anuncio
                            </template>
                            <template #content>
                              <ul class="list-reset w-full ">
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.referral_id">
                                  <b>Anuncio WhatsApp: </b> {{ activeLead.referral_headline }}
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.ad_name">
                                  <b>Anuncio leadgen: </b> {{ activeLead.ad_name }}
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.campaign_name">
                                  <b>Campaña leadgen: </b> {{ activeLead.campaign_name }}
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.awarded">
                                  <b>Premiado</b>
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.week_awarded">
                                  <b>Premiado Semanal</b>
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.campaign_internal_name">
                                  <b>Campaña:</b> {{ activeLead.campaign_internal_name }}
                                </li>
                                <li class="flex text-sm m-1"
                                    v-if="activeLead.campaign_id">
                                  <b>Campaña facebook:</b> {{ activeLead.campaign_id }}
                                </li>
                              </ul>
                            </template>
                          </vue-collapsible-panel>
                        </vue-collapsible-panel-group>
                      </div>

                    </div>
                  </div>
                  <div class="flex flex-col w-4/12 justify-start">
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user" v-model="activeLead.user_id" name="user">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un asesor --
                        </option>
                        <option v-for="(user) in leadsUsersList"
                                v-bind:key="user.id" :value="user.id"
                                class="px-3 py-2 text-sm">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="form" v-model="activeLead.form_id" name="form">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un formulario --
                        </option>
                        <option v-for="(form) in forms"
                                v-bind:key="form.id" :value="form.id"
                                class="px-3 py-2 text-sm">
                          {{ form.name }}
                        </option>
                      </select>
                    </div>
                    <template v-if="activeCompany.uuid === '739cca8d-27e2-4fad-9683-90c452e24735'">
                      <div class="flex w-full">
                        <div class="flex">
                          <p class="px-2">Premiado</p>
                          <div class="m-1">
                            <Toggle v-model="activeLead.awarded"
                                    @change="save()"/>
                          </div>
                        </div>
                        <div class="flex">
                          <p class="px-2">Premiado Semanal</p>
                          <div class="m-1">
                            <Toggle v-model="activeLead.week_awarded"
                                    @change="save()"/>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="flex w-3/12 justify-end">
                    <button class="bg-green-720 h-10 flex rounded-lg  m-1 my-2 p-2 text-white"
                            @click="save()">
                      <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                      Guardar
                    </button>
                    <!--eslint-disable-next-line-->
                    <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeLead()">
                      <XIcon class=" h-7 w-7  mr-1" aria-hidden="true"/>
                    </div>
                  </div>
                </div>
                <div class="w-full flex flex-col md:hidden">
                  <div class=" w-full flex ">
                    <div class="flex w-7/12">
                      <div class="p-1 flex flex-col flex-grow ml-2 sm:m-0">
                        <div class="flex text-sm">
                          <!--eslint-disable-next-line-->
                          <input v-if="editName" class="border-1 p-1" v-model="activeLead.name"
                                 type="text">
                          <b class="p-1 " v-else>{{ activeLead.name }}</b>
                          <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                         @click="editName = !editName" aria-hidden="true"/>
                        </div>
                        <div class="flex my-1 mt-0 content-center">
                          <img class="w-6 h-6 rounded-lg" :src="source" alt="user">
                          <div class="flex-grow ">
                            <div class="text-sm m-2 my-1 self-center">
                              {{ activeLead.form_name }}
                            </div>
                          </div>
                        </div>
                        <div class="flex text-xs m-1">
                          <div>{{ activeLead.created_at.formated_date }}</div>
                        </div>

                        <div class="flex text-sm m-1"
                             v-if="activeLead.referral_id">
                          <b>Anuncio WhatsApp: </b>{{ activeLead.referral_headline }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.ad_name">
                          <b>Anuncio leadgen: </b>{{ activeLead.ad_name }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.campaign_name">
                          <b>Campaña leadgen: </b>{{ activeLead.campaign_name }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.awarded">
                          <b>Premiado</b>
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.week_awarded">
                          <b>Premiado semanal</b>
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.campaign_internal_name">
                          <b>Campaña:</b> {{ activeLead.campaign_internal_name }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeLead.campaign_id">
                          <b>Campaña facebook:</b> {{ activeLead.campaign_id }}
                        </div>
                      </div>
                    </div>
                    <div class="flex w-5/12 justify-end">
                      <button class="bg-green-720 h-10 flex rounded-lg  m-1 my-2 p-2 text-white"
                              @click="save()">
                        <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                        Guardar
                      </button>
                      <!--eslint-disable-next-line-->
                      <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeLead()">
                        <XIcon class=" h-7 w-7 mr-1" aria-hidden="true"/>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full justify-start">
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user1" v-model="activeLead.user_id" name="user">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un asesor --
                        </option>
                        <option v-for="(user) in leadsUsersList"
                                v-bind:key="user.id" :value="user.id"
                                class="px-3 py-2 text-sm">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="form1" v-model="activeLead.form_id" name="form">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un formulario --
                        </option>
                        <option v-for="(form) in forms"
                                v-bind:key="form.id" :value="form.id"
                                class="px-3 py-2 text-sm">
                          {{ form.name }}
                        </option>
                      </select>
                    </div>
                    <template v-if="activeCompany.uuid === '739cca8d-27e2-4fad-9683-90c452e24735'">
                      <div class="flex">
                        <div class="flex p-2 justify-center">
                          <p class="px-2">Premiada</p>
                          <div>
                            <Toggle v-model="activeLead.awarded"
                                    @change="save()"/>
                          </div>
                        </div>
                        <div class="flex p-2 justify-center">
                          <p class="px-2">Premiada Semanal</p>
                          <div>
                            <Toggle v-model="activeLead.week_awarded"
                                    @change="save()"/>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!--section selector-->
              <div class="w-full flex items-center bg-white py-4 overflow-x-auto overflow-y-hidden">
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('info')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'info' }"
                     @keydown="selectTab('info')">
                  Datos
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('images')" v-if="images.length > 0"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'images' }"
                     @keydown="selectTab('images')">
                  Imágenes
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('contact')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'contact' }"
                     @keydown="selectTab('contact')">
                  Contacto
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('products')" v-if="leadProducts.length > 0"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'products' }"
                     @keydown="selectTab('products')">
                  Productos
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('chats')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'chats' }"
                     @keydown="selectTab('chats')">
                  Chats
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('appointments')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'appointments' }"
                     @keydown="selectTab('appointments')">
                  Citas
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('events')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'events' }"
                     @keydown="selectTab('events')">
                  Recordatorios
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('history')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'history' }"
                     @keydown="selectTab('history')">
                  Historial
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('campaign')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'campaign' }"
                     @keydown="selectTab('campaign')">
                  Origen
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('integrations')"
                     @keydown="selectTab('integrations')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'integrations' }">
                  Integraciones
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('documents')" @keydown="selectTab('documents')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'documents' }">
                  Documentos
                </div>
                <div class="p-2 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     v-if="leadOptins.length > 0"
                     @click="selectTab('optins')" @keydown="selectTab('optins')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'optins' }">
                  OptIn
                </div>
              </div>
              <!--lead content-->
              <div class="w-full overflow-y-auto bg-scroll bg-contain h-full"
                   id="messages_container">
                <template v-if="selectedTab === 'info'">
                  <div class="p-4 pt-1">
                    <div class="flex" v-if="activeLead.has_integrations">
                      <div class="bg-amber-100 m-2 p-4 rounded-lg w-full"
                           role="alert">
                        Este contacto ya tiene integraciones:
                        <ul>
                          <li v-for="(integration, key) in activeLead.has_integrations"
                              v-bind:key="key">
                            <div class="m-0" v-for="(type, k) in getKeys(integration.item)"
                                 v-bind:key="k">
                              <b>{{ type.toUpperCase() }}</b><br>
                              <p class="m-0 mr-1"
                                 v-if="!checkIfExist(getKeys(integration.item[type]), 'date')"
                                 style="display: inline-block;">
                                <b>Fecha:</b> {{ integration.date }}
                              </p>
                              <div class="m-0 mr-1"
                                   v-for="(attr, y) in getKeys(integration.item[type])"
                                   style="display: inline-block;" v-bind:key="y">
                                <p v-if="attr === 'is_success'">
                                  <b>Estado:</b>
                                  {{ integration.item[type][attr] ? 'Enviado' : 'Fallido' }} </p>
                                <!--eslint-disable-next-line-->
                                <p
                                  v-if="!checkIfExist(
                                    getKeys(integration.item[type]), 'user_name')
                                    && attr === 'user_id'">
                                  <b>Nombre de usuario:</b>
                                  {{ filterVtigerUser(integration.item[type][attr], type) }}
                                </p>
                                <!--eslint-disable-next-line-->
                                <p
                                  v-if="attr !== 'is_success' && attr !== 'user_id'
                                  && attr !== 'user' && attr !== 'id' && attr !== 'data' ">
                                  <b>{{ translates[attr] }}:</b> {{ integration.item[type][attr] }}
                                </p>
                              </div>
                              <p class="m-0 mr-1"
                                 v-if="!checkIfExist( getKeys(integration.item[type]), 'response')"
                                 style="display: inline-block;">
                                <b>Respuesta:</b> En cola de envío a {{ type }}
                              </p>
                            </div>

                          </li>
                        </ul>
                      </div>
                    </div>
                    <!--buttons abierto cerrrado-->
                    <div class="flex">
                      <div class="w-6/12 py-2 px-1">
                        <button class=" rounded-lg p-2 border-2 w-full bg-sky-100 text-sm
                        text-white"
                                @click="setStatusType('active')"
                                v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'active',
                              'border-sky-400' : selectedStatusType === 'active',
                              'bold' : selectedStatusType === 'active'}">
                          Estados en Gestión
                        </button>
                      </div>
                      <div class="w-6/12 py-2">
                        <button class=" rounded-lg p-2 border-2 w-full bg-sky-100 text-sm
                        text-white"
                                @click="setStatusType('closed')"
                                v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'closed',
                                'border-sky-400' : selectedStatusType === 'closed',
                                'bold' : selectedStatusType === 'closed'}">
                          Estados Cerrados
                        </button>
                      </div>
                    </div>
                    <!--statuses-->
                    <div class="flex flex-wrap scroll-states">
                      <template v-if="isLoadingStatuses">Cargando..</template>
                      <template v-else>
                        <button class=" m-1 border-2 p-1 flex bg-white btn-states justify-center
                        items-center" v-for="(status) in
                              selectedStatusType === 'active' ? activeStatuses : closedStatuses"
                                v-bind:key="status.key" @click="selectStatus(status)"
                                v-bind:class="{ 'bg-sky-400' : activeLead.status?.id === status.id,
                                'border-sky-400' : activeLead.status?.id === status.id,
                                'text-white' : activeLead.status?.id === status.id}">
                          <CalendarIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                        v-if="status.activate_calendar"/>
                          <CashIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                    v-if="status.activate_product"/>
                          <ChevronDoubleRightIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                                  v-if="status.activate_transfer"/>
                          <ExternalLinkIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                            v-if="status.activate_integrations"/>
                          <ColorSwatchIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                           v-if="status.activate_options"/>
                          <p>{{ status.name }}</p>
                        </button>
                      </template>
                    </div>
                    <!--datos-->
                    <div class="md:flex bg-white">
                      <div class="md:w-6/12 w-full p-1">
                        <h6 class="text-left my-1 title-header"><b>DATOS PERSONALES</b></h6>
                        <p class="m-0 text-sm" v-if="activeLead.email"><b>Email: </b>
                          {{ activeLead.email }}</p>
                        <p class="m-0 text-sm" v-if="activeLead.phone"><b>Teléfono: </b>
                          {{ activeLead.phone }}</p>
                        <p class="m-0 text-sm" v-if="activeLead.identification">
                          <b>Identificación / CI / Pasaporte: </b>
                          {{ activeLead.identification }}</p>
                        <p class="m-0 text-sm" v-if="activeLead.operation_number">
                          <b>Número de Operación: </b>
                          {{ activeLead.operation_number }}</p>

                        <div class="m-0 text-sm flex" v-for="(field) in filteredFields"
                             v-bind:key="field.key">
                          <template v-if="field.key === 'imagen'">
                            <div class="flex-col" v-if="images.length < 1">
                              <div class="flex">
                                <b class="my-1 mr-1">{{ field.label }}: </b>
                                <a :href="'https://leadbox.ec'+field.data" target="_blank">
                                  <p class="text-blue-500 underline">
                                    https://leadbox.ec{{ field.data }}
                                  </p>
                                </a>
                              </div>
                              <div class="flex justify-center">
                                <img class="w-6/12" :src="'https://leadbox.ec'+field.data"
                                     alt="imagen">
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <b class="my-1 mr-1">{{ field.label }}: </b>
                            <template v-if="field.editing">
                              <input v-model="field.data" class="border border-black mx-2">
                              <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                     @click="field.editing = false" aria-hidden="true"/>
                            </template>
                            <template v-else>
                              <p class="my-1">{{ field.data }}</p>
                              <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                             @click="field.editing = true" aria-hidden="true"/>
                            </template>
                          </template>
                        </div>
                        <template v-if="showFieldInterface">
                          <div class="form-group flex my-1">
                            <div class="w-1/2 flex flex-col">
                              <!--eslint-disable-next-line-->
                              <label for="fieldTitle"><b>Título:</b></label>
                              <input type="text" v-model="newField.label" id="fieldTitle"
                                     class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                            </div>
                            <div class="w-1/2 flex flex-col">
                              <!--eslint-disable-next-line-->
                              <label for="fieldData"><b>Valor:</b></label>
                              <input type="text" v-model="newField.data" id="fieldData"
                                     class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                            </div>
                          </div>
                          <div class="flex my-1">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                    @click="showFieldInterface = false;
                                    newField = {label: null, data:null,};"
                                    type="button">Cancelar
                            </button>
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="createField()">Aceptar
                            </button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex justify-end my-2">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="showFieldInterface = true;">Agregar campo
                            </button>
                          </div>
                        </template>
                      </div>
                      <div class="md:w-6/12 w-full p-1">
                        <h6 class="text-left my-1 font-semibold title-header">NOTAS</h6>
                        <template v-if="isLoadingConversationNotes">
                          Cargando Notas...
                        </template>
                        <template v-else>
                          <template v-for="(note) in notes" v-bind:key="note.id">
                            <div class="p-4 flex flex-col rounded-lg border-2 border-black-600
                            shadow-xl shadow-black my-2">
                              <p class="font-semibold">{{ note.data }}</p>
                              <p>{{ note.user?.name }}</p>
                              <p>{{ note.created_at?.formated_date }}</p>
                            </div>
                          </template>
                        </template>
                        <template v-if="showNoteInterface">
                          <div class="form-group">
                            <!--eslint-disable-next-line-->
                            <label for="newNote"><b>Nueva Nota</b></label>
                            <textarea class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md" v-model="note"
                                      name="newNote" id="newNote">
                            </textarea>
                          </div>
                          <div class="flex">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                    @click="showNoteInterface = false; note = '';"
                                    type="button">Cancelar
                            </button>
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="createNote()">Aceptar
                            </button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex justify-end">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="showNoteInterface = true;">Crear nota
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="flex" v-if="!activeLead.is_transferred &&
                    (activeLead.is_closed_positive || activeLead.is_closed_negative)">
                      <div class="bg-amber-100 m-2 p-4 rounded-lg w-full"
                           role="alert">
                        Este lead se encuentra cerrado<span>: {{ activeLead.status?.name }}</span>
                      </div>
                    </div>
                    <div class="flex justify-center" v-if="!!activeLead.sold_at">
                      <div class="flex w-full md:w-7/12 justify-evenly">
                        <p class="text-center"><b>Fecha de venta: </b>
                          {{ activeLead.sold_at_formatted }}</p>
                        <p class="text-center"><b>Total Vendido: </b>
                          $ {{ activeLead.sale.toFixed(2) }}</p>
                      </div>
                    </div>
                    <div class="flex" v-if="activeLead.already_attended_by">
                      <div class="bg-amber-100 m-2 p-4 rounded-lg w-full"
                           role="alert">
                        Esta persona ya se encuentra siendo atendida por
                        <strong>{{ activeLead.already_attended_by.user?.name }}</strong>
                        en el formulario "{{ activeLead.already_attended_by.form?.name }}"
                      </div>
                    </div>

                  </div>
                </template>
                <template v-if="selectedTab === 'images'">
                  <div class="p-4">
                    <template v-if="images.length > 0">
<!--                      <h6 class="text-left my-1 font-semibold title-header">IMAGENES</h6>-->
                      <div class="flex flex-wrap">
                        <div class="w-full md:w-4/12 p-2"
                             v-for="(image, index) in images"
                             :key="index">
                          <p class="text-center my-2"><b>Imagen {{ index + 1 }}</b></p>
                          <div class="flex justify-center">
                            <a :href="'https://leadbox.ec' + image" target="_blank">
                              <img class="w-10/12 m-2"
                                   :src="'https://leadbox.ec' + image"
                                   alt="imagen">
                            </a>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <h6 class="text-center my-10">No hay imagenes
                      </h6></template>
                  </div>
                </template>
                <template v-if="selectedTab === 'chats'">
                  <div class="p-4">
                    <template v-if="isLoadingLeadConversations">Cargando...</template>
                    <template v-else>
                      <template v-if="leadConversations.length>0">
                        <template v-for="(conversation) in leadConversations"
                                  v-bind:key="conversation.id">
                          <div @keyDown="goToConversation(conversation.conversation?.uuid)"
                               @click="goToConversation(conversation.conversation?.uuid)"
                               class="p-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-gray-100 cursor-pointer"
                               v-if="conversation.conversation">
                            <div class="text-md px-2">
                              <img class="w-14 h-14 my-2  rounded-full flex"
                                   src="@/assets/default-profile.svg"
                                   alt="user">
                            </div>
                            <div class="flex flex-col">
                              <p class="text-md">
                                <b>{{ conversation.conversation?.name }}</b>
                              </p>
                              <p class="text-md" v-if="conversation.conversation?.user_id">
                                <b>Asignado a:</b> {{ conversation.conversation?.user?.name }}
                              </p>
                              <p class="text-sm">
                                <b>ultima interacción:</b>
                                {{ conversation.conversation?.formatted_last_interaction }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <h6 class="text-center my-10">No hay conversaciones
                        </h6></template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'appointments'">
                  <div class="p-4">
                    <div class="w-full flex bg-white px-4 overflow-x-auto py-2">
                      <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                           @click="selectDatesTab('list-appointments')"
                           v-bind:class="{
                             'border-b-4' : selectedDatesTab === 'list-appointments' }"
                           @keydown="selectDatesTab('list-appointments')">
                        Mis Citas
                      </div>
                      <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                           @click="selectDatesTab('create-appointment')"
                           v-bind:class="{
                             'border-b-4' : selectedDatesTab === 'create-appointment' }"
                           @keydown="selectDatesTab('create-appointment')">
                        Crear Cita
                      </div>
                    </div>
                    <template v-if="selectedDatesTab === 'list-appointments'">
                      <template v-if="isLoadingLeadAppointments">Cargando...</template>
                      <template v-else>
                        <template v-if="leadAppointments.length>0">
                          <template v-for="(appointment) in leadAppointments"
                                    v-bind:key="appointment.id">
                            <div @keyDown="goToAppointment(appointment?.uuid)"
                                 @click="goToAppointment(appointment?.uuid)"
                                 class="p-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-gray-100 cursor-pointer"
                                 v-if="appointment">
                              <div class="flex flex-col">
                                <p class="text-md">
                                  <b>{{ appointment?.patient_name }}</b>
                                </p>
                                <p class="text-md" v-if="appointment?.patient_phone">
                                  <b>Teléfono:</b> {{ appointment?.patient_phone }}
                                </p>
                                <p class="text-md" >
                                  <b>Email:</b> {{ appointment?.patient_email }}
                                </p>
                                <p class="text-md" v-if="appointment?.patient_document">
                                  <b>Identificación:</b>
                                  {{ appointment?.patient_document }}
                                </p>
                                <p class="text-md"
                                   v-if="appointment?.medical_center_id">
                                  <b>Centro Médico:</b>
                                  {{ appointment?.medical_center_city }} -
                                  {{ appointment?.medical_center_name }}
                                </p>
                                <p class="text-md" v-if="appointment?.doctor_id">
                                  <b>Tratamiento:</b>
                                  {{ appointment?.doctor_service }}
                                  {{ appointment?.doctor_name }}
                                </p>
                                <p class="text-md" v-if="appointment?.specialty_id">
                                  <b>Especialidad:</b>
                                  {{ appointment?.specialty_name }}
                                </p>
                                <p class="text-md" v-if="appointment?.procedure_id">
                                  <b>Zona:</b>
                                  {{ appointment?.procedure_name }}
                                </p>
                                <p class="text-md" v-if="appointment?.user_id">
                                  <b>Asignado a:</b> {{ appointment?.user_name }}
                                </p>
                                <p class="text-md">
                                  <b>Fecha:</b>
                                  {{ appointment?.date }}
                                  {{ appointment?.start_time }} -
                                  {{ appointment?.end_time }}
                                </p>
                              </div>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <h6 class="text-center my-10">Lead no tiene citas
                          </h6></template>
                      </template>
                    </template>
                    <template v-if="selectedDatesTab === 'create-appointment'">
                      <div class="justify-start p-4">
                        <template v-if="isLoadingCreateAppointment">
                          <p class="text-center">Cargando...</p>
                        </template>
                        <template v-else>
                          <h6 class="text-left my-1 font-semibold ">CREAR CITA</h6>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                          <select v-model="newAppointment.connection"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsCities">
                            <option disabled :value="null">-- Seleccione la conexión --</option>
                            <option v-for="(connection) in appointmentConnections"
                                    v-bind:key="connection.id"
                                    :value="connection">
                              {{ connection.name }}
                            </option>
                          </select>
                          <template v-if="newAppointment.connection">
                            <div class="flex flex-col md:flex-row">
                              <div class="flex-col w-full px-1">
                                <div class="p-2"><b>Nombre y Apellido:</b></div>
                                <!--eslint-disable-next-line-->
                                <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.name" type="text">
                              </div>
                            </div>
                            <div class="flex flex-col md:flex-row">
                              <div class="flex-col w-full md:w-1/2 px-1">
                                <div class="p-2"><b>Teléfono:</b></div>
                                <!--eslint-disable-next-line-->
                                <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.phone" type="tel">
                              </div>
                              <div class="flex-col w-full md:w-1/2 px-1">
                                <div class="p-2"><b>Email:</b></div>
                                <!--eslint-disable-next-line-->
                                <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.email" type="text">
                              </div>
                            </div>
                            <div class="flex-col w-full md:w-1/2 px-1">
                              <input type="radio" id="cedula" value="cedula" class="m-3"
                                     v-model="newAppointment.type">
                              <!--eslint-disable-next-line-->
                              <label for="cedula" class="my-4">Cédula</label>
                              <input type="radio" id="ruc" name="ruc" value="ruc" class="m-3"
                                     v-model="newAppointment.type">
                              <!--eslint-disable-next-line-->
                              <label for="ruc" class="my-4">RUC</label>
                              <input type="radio" id="passport" name="passport" value="passport"
                                     class="m-3" v-model="newAppointment.type">
                              <!--eslint-disable-next-line-->
                              <label for="passport" class="my-4 mr-4">Pasaporte</label>
                              <input
                                id="document" name="identificacion"
                                placeholder="Número de identificación" type="text"
                                v-model="newAppointment.document"
                                class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                            </div>
                            <div class="flex-col">
                              <p class="my-1"><b>Número de Ficha:</b></p>
                              <!--eslint-disable-next-line-->
                              <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.number" type="text">
                            </div>
                            <div class="flex-col" v-if="newAppointment.cities.length > 0">
                              <p class="my-1"><b>Ciudad:</b></p>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.city"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsMedicalCenters">
                                <option disabled :value="null">-- Seleccione la ciudad --</option>
                                <option v-for="(city) in newAppointment.cities" v-bind:key="city.id"
                                        :value="city">
                                  {{ city.name }}
                                </option>
                              </select>
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.sectors.length > 0 && newAppointment.city">
                              <p class="my-1"><b>Sector:</b></p>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.sector"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSectorMedicalCenters">
                                <option disabled :value="null">-- Seleccione el sector --</option>
                                <option v-for="(sector, sk) in newAppointment.sectors"
                                        v-bind:key="sk" :value="sector.sector">
                                  {{ sector.sector }}
                                </option>
                              </select>
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.medicalCenters.length > 0
                                 && newAppointment.city">
                              <p class="my-1"><b>Centro:</b></p>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.medicalCenter"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsDoctorsAndSpecialties">
                                <option disabled :value="null">-- Seleccione el local --</option>
                                <option v-for="(medicalCenter) in newAppointment.medicalCenters"
                                        v-bind:key="medicalCenter.id"
                                        :value="medicalCenter">
                                  {{ medicalCenter.name }}
                                </option>
                              </select>
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.doctors.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                              <p class="my-1"><b>Tratamientos:</b></p>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.doctor"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSpecialtiesByDoctor">
                                <option disabled :value="null">-- Seleccione el local --</option>
                                <option v-for="(doctor) in newAppointment.doctors"
                                        v-bind:key="doctor.id"
                                        :value="doctor">
                                  {{ doctor.name }}
                                </option>
                              </select>
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.specialties.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                              <p class="my-1"><b>Zonas:</b></p>
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.specialty"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSchedules">
                                <option disabled :value="null">-- Seleccione el local --</option>
                                <option v-for="(specialty) in newAppointment.specialties"
                                        v-bind:key="specialty.id"
                                        :value="specialty">
                                  {{ specialty.name }}
                                </option>
                              </select>
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.medicalCenter && newAppointment.city">
                              <p class="my-1"><b>Fecha:</b></p>
                              <!--eslint-disable-next-line-->
                              <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.date" type="date"
                                     @change="getAppointmentsSchedules">
                            </div>
                            <div class="flex-col"
                                 v-if="newAppointment.medicalCenter && newAppointment.city
                             && newAppointment.date && newAppointment.schedules.length > 0">
                              <p class="my-1"><b>Horario:</b></p>
                       <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                              <select v-model="newAppointment.schedule"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                                <option disabled :value="null">-- Seleccione el horario --</option>
                                <option v-for="(schedule) in newAppointment.schedules"
                                        v-bind:key="schedule.id"
                                        :value="schedule">
                                  {{ schedule.startLabel }} - {{ schedule.endLabel }}
                                </option>
                              </select>
                            </div>
                            <div class="w-full flex">
                              <div class="m-1">
                                <Toggle v-model="newAppointment.addProduct"/>
                              </div>
                              <p class="ml-1">Agregar producto/s?</p>
                            </div>
                            <template v-if="newAppointment.addProduct">
                              <div class="my-1">
                                <div class="p-4 bg-gray-100">
                                  <div v-for="category in categoriesCopy" :key="category.id"
                                       class="mb-4">
                                    <!-- eslint-disable -->
                                    <div @click="toggleCategory(category.slug)"
                                         class="bg-teal-600 text-white p-4 rounded-t-lg cursor-pointer flex
                             justify-between items-center">
                                      <h2 class="text-sm font-bold">{{ category.name }}</h2>
                                      <!-- Chevron para mostrar si está abierto o cerrado -->
                                      <svg v-if="category.open" xmlns="http://www.w3.org/2000/svg"
                                           class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                           stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7"/>
                                      </svg>
                                      <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                           viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M19 9l-7 7-7-7"/>
                                      </svg>
                                    </div>
                                    <!-- Contenido del collapsible -->
                                    <div v-show="category.open"
                                         class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-b-lg
                             border border-gray-300">
                                      <!-- Productos -->
                                      <div v-for="product in category.products" :key="product.id"
                                           class="p-4 bg-white rounded-lg shadow-md border border-gray-300">
                                        <div class="flex justify-between items-center mb-4">
                                          <!-- Componente de Toggle -->
                                          <Toggle v-model="product.selected"/>
                                          <p class="text-gray-800 font-bold">{{ product.name }}</p>
                                        </div>

                                        <!-- Precio o Precio con Descuento -->
                                        <div class="flex items-center justify-between mb-4">
                                          <p class="text-gray-800 font-medium">
                                            Precio Normal: $ {{ product.price }}
                                          </p>
                                          <template
                                            v-if="product.discount_price && product.discount_price !== product.price">
                                            <Toggle v-model="product.use_discount"
                                                    @change="updatePrice(product)"/>
                                            <p class="text-gray-800 font-medium">
                                              Precio con Descuento: $ {{ product.discount_price }}
                                            </p>
                                          </template>

                                        </div>

                                        <!-- Mostrar Precio Final Seleccionado -->
                                        <div class="mb-4">
                                          <!-- eslint-disable -->
                                          <label class="text-sm text-gray-600">Precio Final</label>
                                          <input v-model="product.final_price" type="text" readonly
                                                 class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                        </div>

                                        <!-- Cantidad -->
                                        <div class="flex justify-between mb-4">
                                          <div class="w-1/2 pr-2">
                                            <!-- eslint-disable -->
                                            <label class="text-sm text-gray-600">Cantidad</label>
                                            <input v-model="product.quantity" type="number" min="1"
                                                   placeholder="1" @change="updatePrice(product)"
                                                   class="appearance-none rounded-none block w-full px-3 py-2
                                       border border-gray-300 bg-gray-50 text-gray-700"/>
                                          </div>
                                          <div class="w-1/2 pr-2">
                                            <!-- eslint-disable -->
                                            <label class="text-sm text-gray-600">No. de Citas</label>
                                            <input v-model="product.number_dates" type="number" min="1"
                                                   placeholder="1"
                                                   class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                          </div>
                                        </div>

                                        <!-- Nota -->
                                        <div class="mb-4">
                                          <!-- eslint-disable -->
                                          <label class="text-sm text-gray-600">Nota</label>
                                          <textarea v-model="product.note" placeholder="Agregar nota"
                                                    class="appearance-none rounded-none block w-full px-3 py-2
                                        border border-gray-300 bg-gray-50 text-gray-700">
                              </textarea>
                                        </div>

                                        <!-- Total -->
                                        <div class="text-right text-gray-800 font-bold">
                                          <p>Total: $ {{ product.quantity * product.final_price }}</p>
                                        </div>
                                        <!-- Total -->
                                        <div class="text-right text-gray-800 font-bold">
                                          <p>Total Sesiones: {{ product.quantity * product.number_dates }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-full flex text-lg justify-center">
                                    <p><b>TOTAL: </b>
                                      $ {{ total_products }}</p>
                                  </div>
                                  <div class="w-full flex text-lg justify-center">
                                    <p><b>TOTAL SESIONES: </b> {{ total_dates }}</p>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div class="flex" v-if="newAppointment.schedule">
                              <button class="group relative w-full flex justify-center py-2 px-4
                              border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                      @click="createAppointment()">Crear
                              </button>
                            </div>
                          </template>
                        </template>

                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'contact'">
                  <div class="flex flex-col p-4">
                    <template v-if="activeLead.contact_id">
                      <div class="p-1">
                        <p><b>Nombre:</b> {{ activeLead.contact?.name }}</p>
                      </div>
                      <div class="p-1 flex" v-if="activeLead.contact?.email">
                        <p><b>Email:</b> {{ activeLead.contact?.email }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeLead.contact?.email" title="Enviar email"
                           :href="'mailto:'+activeLead.contact?.email">
                          <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                      </div>
                      <div class="p-1 flex" v-if="activeLead.contact?.secondary_email">
                        <p><b>Email Secundario:</b> {{ activeLead.contact?.secondary_email }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeLead.contact?.secondary_email" title="Enviar email"
                           :href="'mailto:'+activeLead.contact?.secondary_email">
                          <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                      </div>
                      <div class="p-1 flex">
                        <p><b>Teléfono: </b>{{ activeLead.contact?.phone }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeLead.contact?.phone" title="Llamar"
                           :href="'tel:'+activeLead.contact?.phone">
                          <PhoneOutgoingIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeLead.contact?.phone" title="Enviar mensaje"
                           :href="'https://api.whatsapp.com/send?phone='+activeLead.contact?.phone?.replace('+', '')"
                           target="_blank">
                          <img class="w-6 h-6 mx-2" src="@/assets/whatsapp.png"
                               alt="whatsapp">
                        </a>
                      </div>
                      <div class="p-1" v-if="activeLead.contact?.document">
                        <b>Documento:</b> {{ activeLead.contact?.document }}
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-center my-10">No tiene contacto</p>
                    </template>
                  </div>
                  <div class="flex flex-col px-4 overflow-y-auto h-fit"
                       v-if="activeLead.contact_id">
                    <template v-if="activeLead.contact?.leads.length>0">
                      <h6 class="text-left my-1 font-semibold title-header">Leads</h6>
                      <template v-for="(ld) in activeLead.contact?.leads"
                                v-bind:key="ld.id">
                        <div class="p-4 flex rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer
                            hover:bg-gray-200"
                             v-bind:class="{
                                'bg-stone-400': ld.uuid === activeLead?.uuid,
                                'bg-gray-100': ld.uuid !== activeLead?.uuid,
                                 }"
                        >
                          <div class="flex-col flex-auto "
                               :title="ld.uuid === activeLead?.uuid ?
                             'Este es el lead abierto':'Has click para abrir este lead'"
                               @click="getLead(ld.uuid);"
                               @keydown="getLead(ld.uuid);">
                            <div class="flex">
                              <p class="font-semibold mx-1">{{ ld.name }}</p>
                              <p v-if="ld.uuid === activeLead?.uuid" class="text-orange-500 ">
                                (lead activo)
                              </p>
                            </div>
                            <div class="flex">
                              <img class="w-6 h-6 rounded-lg mx-1" :src="sourceLead(ld)" alt="user">
                              <p>{{ ld.form_name }}</p>
                            </div>
                            <div class="flex" v-if="ld.status">
                              <p class="mx-1"><b>Estado: </b></p>
                              <div class="rounded-full text-white p-2 text-xs m-1"
                                   :style="{backgroundColor: ld.status?.color}">
                              </div>
                              <p>{{ ld.status?.name }}</p>
                            </div>
                            <div class="flex" v-if="ld.channel_name">
                              <p class="mx-1"><b>Canal: </b></p>
                              <p>{{ ld.channel_name }}</p>
                            </div>
                            <p class="text-small mx-1"><b>Fecha de creación: </b>
                              {{ ld.created_at?.date }}
                            </p>
                          </div>
                          <div class=" flex-none  p-2 hover:bg-gray-300 cursor-pointer"
                               v-if="ld.uuid !== activeLead?.uuid"
                               title="Has click para eliminar este lead"
                               @click="openDeleteContactLeadModal(ld)"
                               @keydown="openDeleteContactLeadModal(ld);">
                            <TrashIcon class="w-7 mr-1 text-red-600" aria-hidden="true"/>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-if="activeLead.contact?.orders.length>0">
                      <h6 class="text-left my-1 font-semibold title-header">Órdenes</h6>
                      <div v-for="(order) in activeLead.contact?.orders"
                           v-bind:key="order.id"
                           class="p-2 flex flex-col rounded-lg border-2 border-black-600
                               shadow-xl shadow-black my-3 bg-gray-100">
                        <p class="text-center text-lg my-1"><b>Orden No.{{ order.uuid }}</b></p>
                        <div class="bg-white p-2 rounded-lg flex">
                          <div class="w-1/2">
                            <p class="text-center"><b>Método de pago</b></p>
                            <p class="text-center">{{ getPayment(order.payment_method) }}</p>
                          </div>
                          <div class="w-1/2">
                            <p class="text-center"><b>Fecha de compra</b></p>
                            <p class="text-center">{{ order.created_at.formated_date }}</p>
                          </div>
                          <div class="w-1/2">
                            <p class="text-center"><b>Total</b></p>
                            <p class="text-center">$ {{ order.total }}</p>
                          </div>
                        </div>
                        <p class="text-center text-lg my-1"><b>Carrito de compras</b></p>
                        <div class="p-2 w-full overflow-x-auto shadow-md sm:rounded-lg
                            bg-white">
                          <table class="table-auto w-full border border-gray-400">
                            <thead>
                            <tr class="bg-grey-200 border border-gray-200">
                              <th class="text-center border border-gray-200">
                                <b>Producto</b>
                              </th>
                              <th class="text-center border border-gray-200">
                                <b>Cantidad</b></th>
                              <th class="text-center border border-gray-200"><b>Precio</b>
                              </th>
                              <th class="text-center border border-gray-200"><b>Total</b></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, key) in order.cart" v-bind:key="key">
                              <tr v-if="item.product" class="border border-gray-200">
                                <td class="flex justify-center border border-gray-200
                                      content-center">
                                  <img class="w-12 h-12 my-2 rounded-full self-center"
                                       :src="order.is_woocommerce ? item.product?.image : 'https://leadbox.ec/storage/'+item.product?.image"
                                       :alt="item.product?.name">
                                  <div class="flex flex-col mx-2 content-center">
                                    <p>{{ item.product?.name }} x {{ item.quantity }}</p>
                                    <div v-if="item.note">
                                      <p><b>Observación:</b> {{ item.note }}</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.quantity }}
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.price / item.quantity }}
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.price }}
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </template>
                  </div>

                </template>
                <template v-if="selectedTab === 'products'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadProducts">Cargando...</template>
                    <template v-else>
                      <template v-if="leadProducts.length>0">
                        <div v-for="(leadProduct) in leadProducts"
                             v-bind:key="leadProduct.id"
                             class="p-3 flex rounded-lg border-2 border-black-600 shadow-xl
                             shadow-black my-3 bg-gray-100">
                          <div class="flex w-full items-center">
                            <!-- Imagen ocupando 1/4 del espacio -->
                            <div class="w-1/4 flex items-center justify-center"
                                 v-if="leadProduct.product?.image">
                              <img class="w-3/4 h-auto rounded-sm"
                                   :src="order.is_woocommerce ? leadProduct.product?.image : 'https://leadbox.ec/storage/' + leadProduct.product?.image"
                                   :alt="leadProduct.product?.name">
                            </div>
                            <!-- Texto ocupando 3/4 del espacio y alineado a la izquierda -->
                            <div class="w-3/4 flex flex-col p-2">
                              <p class="text-left text-lg my-1">
                                <b>{{ leadProduct.product?.name }}</b>
                              </p>
                              <p class="text-left text-sm my-1">
                                <b>Cantidad: </b>
                                {{ leadProduct.quantity }}
                              </p>
                              <p class="text-left text-sm my-1">
                                <b>Precio Unitario: </b>
                                {{activeCompany.money_sign}} {{ leadProduct.product?.price }}
                              </p>
                              <p class="text-left text-sm my-1">
                                <b>Total: </b>
                                {{activeCompany.money_sign}} {{ leadProduct.price }}
                              </p>
                              <p class="text-left text-sm my-1" v-if="leadProduct.note">
                                <b>Nota: </b>
                                {{ leadProduct.note }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center my-10">No hay productos</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'events'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadEvents">Cargando...</template>
                    <template v-else>
                      <template v-if="leadEvents.length>0">
                        <div v-for="(event) in leadEvents"
                             v-bind:key="event.id">
                          <div class="p-2 mx-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-grey-100">
                            <div class="rounded-full text-white self-center w-5 h-5 mx-4"
                                 :style="{ 'background-color' : event.color }">
                            </div>
                            <div class="flex-col m-2">
                              <p class="text-md">
                                {{ event.title }}</p>
                              <p>Fecha del evento: <b>
                                {{ event.start_date }}
                              </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <h6 class="text-center my-10">No hay eventos</h6>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'history'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadHistory">Cargando...</template>
                    <template v-else>
                      <template v-if="leadHistories.length>0">
                        <div v-for="(history) in leadHistories"
                             v-bind:key="history.id"
                             class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="rounded-full text-white self-center p-4"
                               :style="{ 'background-color' : history.status?.color }">
                          </div>
                          <div class="flex flex-col m-4 my-2">
                            <p class="text-md">
                              {{ history.status ? history.status.name : '' }}
                              {{ history.user ? ' - ' + history.user.name :
                              '' }}
                            </p>
                            <p class="text-sm">{{ history.created_at.formated_date }}</p>
                            <p class="text-sm" v-if="history.observations">
                              {{ history.observations }}
                            </p>
                            <p class="text-sm" v-if="history.is_transfer && history.form">
                              Fue transferido desde el formulario
                              <b>{{ history.form ? history.form.name : 'Formulario Eliminado' }}</b>
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center text-center">No hay Historial</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'optins'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadOptins">Cargando...</template>
                    <template v-else>
                      <template v-if="leadOptins.length>0">
                        <div v-for="(optin) in leadOptins"
                             v-bind:key="optin.id"
                             class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3"
                             v-bind:class="{
                                'bg-stone-400': optin.lead_uuid === activeLead?.uuid,
                                'bg-gray-100': optin.lead_uuid !== activeLead?.uuid,
                                 }"
                        >
                          <div class="flex flex-col m-4 my-2">
                            <div class="flex">
                              <p class="text-md">
                                <b>Nombre: </b>{{ optin.name }}
                              </p>
                              <p v-if="optin.lead_uuid === activeLead?.uuid"
                                 class="text-orange-500 ">
                                (lead activo)
                              </p>
                            </div>
                            <p class="text-sm" v-if="optin.email">
                              <b>Email: </b>{{ optin.email }}
                            </p>
                            <p class="text-sm" v-if="optin.phone">
                              <b>Teléfono: </b>{{ optin.phone }}
                            </p>
                            <p class="text-sm" v-if="optin.identification">
                              <b>Identifiación (C.I): </b>{{ optin.identification }}
                            </p>
                            <p class="text-sm">
                              <b>Fecha de registro: </b>{{ optin.created_at }}
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center text-center">No hay registros de OptIn</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'integrations'">
                  <div class="p-4">
                    <template v-if="activeLead.integrations">
                      <div v-for="(integration, type) in activeLead.integrations"
                           v-bind:key="type">
                        <div class="mb-5" v-if="type === 'vtiger' || type === 'teojama'">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.user_id">
                            <strong>ID de usuario:</strong> {{ integration.user_id }}
                          </div>
                          <div v-if="integration.user_name">
                            <strong>Nombre de usuario:</strong> {{ integration.user_name }}
                          </div>
                          <div v-if="integration.user_id && type === 'vtiger'
                            && !integration.user_name">
                            <strong>Usuario:</strong> {{
                              filterVtigerUser(integration.user_id, type)
                            }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'oracle-sales' || type === 'pilot' ">
                          <div class="mb-3" v-if="type === 'oracle-sales'">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div class="mb-3" v-if="type === 'pilot'">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.status">
                            <strong>Estado:</strong> {{ integration.status }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'mailer-lite' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.status">
                            <strong>Estado:</strong> {{ integration.status }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'nova-scoring' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Mensaje:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.requerimiento">
                            <strong>Requerimiento:</strong> {{ integration.requerimiento }}
                          </div>
                          <div v-if="integration.calificacion">
                            <strong>Calificación:</strong> {{ integration.calificacion }}
                          </div>
                          <div v-if="integration.respuesta">
                            <strong>Respuesta:</strong> {{ integration.respuesta }}
                          </div>
                          <div v-if="integration.created_at">
                            <strong>Fecha de registro:</strong> {{ integration.created_at }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'opt-in' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Mensaje:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.number">
                            <strong>Id:</strong> {{ integration.number }}
                          </div>
                          <div v-if="integration.identification">
                            <strong>Identifiación (C.I):</strong> {{ integration.identification }}
                          </div>
                          <div v-if="integration.phone">
                            <strong>Teléfono:</strong> {{ integration.phone }}
                          </div>
                          <div v-if="integration.email">
                            <strong>Correo Electrónico:</strong> {{ integration.email }}
                          </div>
                          <div v-if="integration.created_at">
                            <strong>Fecha de registro:</strong> {{ integration.created_at }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'nova-scoring-certification' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Mensaje:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.requerimiento">
                            <strong>Requerimiento:</strong> {{ integration.requerimiento }}
                          </div>
                          <div v-if="integration.calificacion">
                            <strong>Calificación:</strong> {{ integration.calificacion }}
                          </div>
                          <div v-if="integration.respuesta">
                            <strong>Respuesta:</strong> {{ integration.respuesta }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'gxm' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.id">
                            <strong>ID respuesta:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.data">
                            <strong>Datos recibidos:</strong> {{ integration.data }}
                          </div>
                          <div v-if="integration.payload">
                            <strong>Datos enviados:</strong> {{ integration.payload }}
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-center">Aquí tendrá un estado del lead con respecto a todas
                        las integraciones instaladas</p>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'campaign'">
                  <div class="flex flex-col p-4">
                    <template v-if="activeLead.referral_id">
                      <p><b>ID:</b> {{ activeLead.referral_id }}</p>
                      <p><b>Tipo:</b> {{ activeLead.referral_type }}</p>
                      <p><b>Url:</b> <a :href="activeLead.referral_url" target="_blank"
                                        class="text-sky-500 underline">
                        {{ activeLead.referral_url }}</a></p>
                      <p> {{ activeLead.referral_headline }}</p>
                      <p v-html="activeLead.referral_body"></p>
                    </template>
                    <template
                      v-if="activeLead.ad_id !== null || activeLead.ad_name !== null ||
                      activeLead.campaign_id !== null || activeLead.campaign_name !== null ||
                      activeLead.platform !== null || activeLead.post !== null">
                      <p><b>Id anuncio:</b> {{ activeLead.ad_id }}</p>
                      <p><b>Nombre anuncio:</b> {{ activeLead.ad_name }}</p>
                      <p><b>Id campaña:</b> {{ activeLead.campaign_id }}</p>
                      <p><b>Nombre campaña:</b> {{ activeLead.campaign_name }}</p>
                      <p><b>Plataforma:</b> {{ activeLead.platform }}</p>
                      <p v-if="activeLead.post !== null"><b>Post:</b>
                        <a :href="activeLead.post"
                           target="_blank"
                           class="text-sky-500 underline">
                          {{ activeLead.post }}</a></p>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'documents'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadDocuments">Cargando...</template>
                    <template v-else>
                      <template v-if="leadDocuments.length>0">
                        <div v-for="(document) in leadDocuments"
                             v-bind:key="document.id"
                             class="p-3 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="flex flex-col w-full">
                            <p class="text-lg text-center my-1">
                              <b>{{ document.original_name }}</b>
                            </p>
                            <div class="flex text-sm my-1">
                              <div class="w-full md:w-6/12 text-center">
                                <p class=""><b>Creado por:</b></p>
                                <p class="">{{
                                    document.user
                                      ? document.user.name : 'Usuario eliminado'
                                  }}</p>
                              </div>
                              <div class="w-full md:w-6/12 text-center">
                                <p class=""><b>Fecha de creación:</b></p>
                                <p class="">{{ document.created_at.formated_date }}</p>
                              </div>
                            </div>
                            <div class="flex">
                              <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-red-600 hover:bg-red-700 m-1" @click="openEmailModal(document)">
                                Compartir
                              </button>
                              <a class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-sky-600 hover:bg-sky-700 m-1" target="_blank"
                                 :href="'https://leadbox.ec'+document.path">Descargar</a>
                              <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-yellow-600 hover:bg-yellow-700 m-1"
                                      v-if="activeUser.is_superadmin ||
                                      activeUser.is_supervisor || activeUser.is_admin"
                                      @click="openDeleteFileModal(document)">Eliminar
                              </button>
                            </div>

                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center my-10">No hay documentos</p>
                      </template>
                    </template>
                    <div class="w-full flex justify-center p-2">
                      <button class="group relative w-1/3 flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 mx-auto" type="button"
                              @click="showDocumentModal = true;">Subir documento
                      </button>
                    </div>
                  </div>
                </template>
                <StatusModal :showModal="showModal" @close="showModal = false"
                             :status="modalStatus" @close-update="closeModalUpdate"/>
                <!--email modal-->
                <ModalBox :showing="showEmailModal" @close="showEmailModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>Enviar documento {{ selectedDocument.original_name }} a:</b></p>
                  </div>
                  <div>
                    <div class="flex flex-col">
                      <!--eslint-disable-next-line-->
                      <label for="title"><b>Email:</b></label>
                      <input type="email" v-model="documentReceptor" id="title"
                             class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                    </div>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showEmailModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendEmail()">Aceptar
                    </button>
                  </div>
                </ModalBox>
                <!--document modal-->
                <ModalBox :showing="showDocumentModal" @close="showDocumentModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>Subir un documento a este lead:</b></p>
                  </div>
                  <div>
                    <!--eslint-disable-next-line-->
                    <input ref="fileInput" type="file" id="file-input"
                           @change="onInputChange" class="my-1 appearance-none rounded-none
                           relative block w-full p-2 border border-gray-300 placeholder-gray-500
                           text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                  </div>
                  <div class="flex w-full justify-center flex-wrap" v-if="files.length > 0">
                    <template v-for="(file, key) in files" v-bind:key="key">
                      <div class="flex flex-col w-1/2 justify-center ">
                        <img class="m-1" src="@/assets/document.png" :alt="file.file.name"
                             :title="file.file.name"/>
                        <button class="m-1 text-white bg-red-500 rounded-md"
                                @click="removeFile(file)">
                          Eliminar
                        </button>
                      </div>
                    </template>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDocumentModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendFiles()">Aceptar
                    </button>
                  </div>
                </ModalBox>
                <!--delete file modal-->
                <ModalBox :showing="showDeleteFileModal" @close="showDeleteFileModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>¿Quieres eliminar el documento {{ selectedDocument.original_name }}?</b>
                    </p>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDeleteFileModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="deleteFile()">Aceptar
                    </button>
                  </div>
                </ModalBox>

                <!--delete contact lead modal-->
                <ModalBox :showing="showDeleteContactLeadModal"
                          @close="showDeleteContactLeadModal = false">
                  <div class="flex flex-col p-4">
                    <p class="text-black">
                      <b>¿Estás Seguro?</b>
                    </p>
                    <p class="text-black">
                      ¿Quiéres eliminar el lead <b>{{ selectedContactLead?.name }}</b>,
                      perteneciende al formulario <b>{{ selectedContactLead.form_name }}</b>,
                      proveniente de <b>{{ selectedContactLead.form_provider }}?</b>
                    </p>
                    <p class="text-red-600">Esta acción no se puede revertir</p>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDeleteContactLeadModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="deleteContactLead()">Aceptar
                    </button>
                  </div>
                </ModalBox>
                <SemaphoreItem v-if="probabilities.length > 0" :lead="activeLead"
                               @update-lead="save"/>

              </div>
              <!--bottom actions lead-->
              <div class="flex-col p-1">
                <div class="flex justify-center">
                  <div class="flex-col w-full md:w-5/12 p-3">
                    <p class="text-center"><b>Canal:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="activeLead.channel_id"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                      <option disabled :value="null">-- Seleccione un canal --</option>
                      <option v-for="(channel) in channels" v-bind:key="channel.id"
                              :value="channel.id">
                        {{ channel.name }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col w-full md:w-5/12 p-3">
                    <p class="text-center"><b>Ubicación:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="activeLead.location_id"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                      <option disabled :value="null">-- Seleccione una ubicacion --</option>
                      <option v-for="(location) in locations" v-bind:key="location.id"
                              :value="location.id">
                        {{ location.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="flex w-full justify-center ">
                  <!-- eslint-disable-next-line-->
                  <a :href="'mailto:'+activeLead.email"
                     :title="'Enviar email a '+activeLead.email"
                     v-if="activeLead.email">
                    <MailIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-email" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a :title="'Llamar al 0'+activeLead.phone_national_number"
                     :href="'tel:0'+activeLead.phone_national_number"
                     v-if="activeLead.phone_national_number">
                    <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-phone" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a
                    :href="'https://api.whatsapp.com/send?phone='+activeLead.phone.replace('+', '')"
                    v-if="activeLead.phone" target="_blank"
                    :title="'Enviar mensaje de whatsapp a 0'+activeLead.phone_national_number">
                    <ChatIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-chat" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a :href="'sip:0'+activeLead.phone_national_number"
                     v-if="activeLead.phone && activeCompany.uuid
                     === 'b7a26e43-0168-4e20-a22b-5a8186e85a98'"
                     :title="'llamar por Issabel a 0'+activeLead.phone_national_number">
                    <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-phone-issabel" aria-hidden="true"/>
                  </a>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="mt-32 text-center">Selecciona un lead</p>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker';
import Toggle from '@vueform/toggle';
import {
  UserIcon, ArchiveIcon, CheckIcon, CollectionIcon, SearchIcon, XIcon, TrashIcon, UsersIcon,
  PencilAltIcon, RefreshIcon, LockClosedIcon, CalendarIcon, CashIcon, ChevronDoubleRightIcon,
  SwitchHorizontalIcon, MailIcon, PhoneOutgoingIcon, NewspaperIcon, PhoneIcon, ChatIcon, MenuIcon,
  ExternalLinkIcon, ColorSwatchIcon,
} from '@heroicons/vue/outline';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import {
  getToken, onMessage,
} from 'firebase/messaging';
import DropDown from '@/components/DropDown.vue';
import LeadOptionsDropDown from '@/components/LeadOptionsDropDown.vue';
import StatusModal from '@/components/StatusModal.vue';
import SemaphoreItem from '@/components/SemaphoreItem.vue';
import ModalBox from '@/components/ModalBox.vue';
import LoadingBox from '@/components/LoadingBox.vue';
import AsesorItem from '@/components/chat/AsesorItem.vue';
import StatusItem from '@/components/leads/StatusItem.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import FormDropDown from '@/components/FormDropDown.vue';
import AreaDropDown from '@/components/AreaDropDown.vue';
import LeadNotificationsDropDown from '@/components/LeadNotificationsDropDown.vue';
import MessageNotificationsDropDown from '@/components/MessageNotificationsDropDown.vue';
import EventsDropDown from '@/components/EventsDropDown.vue';
import axios from '@/http/axios/index';
import LeadItem from '@/components/leads/LeadItem.vue';
import MainDropDown from '@/components/MainDropDown.vue';
import ProbabilitiesDropDown from '@/components/ProbabilitiesDropDown.vue';
import ChannelsDropDown from '@/components/ChannelsDropDown.vue';

// Stylesheet
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';
import LocationsDropDown from '@/components/LocationsDropDown.vue';

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default {
  name: 'LeadboxLeads',
  displayName: 'LeadboxLeads',
  components: {
    LocationsDropDown,
    LoadingBox,
    SemaphoreItem,
    LeadNotificationsDropDown,
    MessageNotificationsDropDown,
    EventsDropDown,
    StatusModal,
    MainDropDown,
    CompanyDropDown,
    AsesorItem,
    StatusItem,
    DropDown,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    LeadOptionsDropDown,
    ModalBox,
    LeadItem,
    ColorSwatchIcon,
    ExternalLinkIcon,
    ChevronDoubleRightIcon,
    CashIcon,
    ArchiveIcon,
    CheckIcon,
    CollectionIcon,
    UserIcon,
    SearchIcon,
    XIcon,
    TrashIcon,
    NewspaperIcon,
    UsersIcon,
    PencilAltIcon,
    MenuIcon,
    RefreshIcon,
    LockClosedIcon,
    SwitchHorizontalIcon,
    MailIcon,
    PhoneOutgoingIcon,
    LitepieDatepicker,
    CalendarIcon,
    // eslint-disable-next-line vue/no-unused-components
    FormDropDown,
    AreaDropDown,
    ChatIcon,
    PhoneIcon,
    ProbabilitiesDropDown,
    ChannelsDropDown,
    Toggle,
  },
  data() {
    return {
      files: [],
      showFieldInterface: false,
      showCreateFieldInterface: false,
      showAssignModal: false,
      showAssignOneModal: false,
      isLoadingAssignAuto: false,
      isLoadingAssign: false,
      selectAssignUsers: false,
      assignUsersList: [],
      newField: {
        label: null,
        data: null,
      },
      createNewField: {
        label: null,
        data: null,
        name: null,
      },
      categoriesCopy: [],
      assignUserModal: null,
      translates: {
        response: 'Respuesta',
        is_success: 'Estado',
        id: 'Id',
        user_id: 'Id de usuario',
        user_name: 'Nombre de usuario',
        date: 'Fecha',
        status: 'Estado respuesta',
        payload: 'Datos enviados',
      },
      newLead: {
        form: null,
        fields: [],
        addProduct: false,
        category: null,
        product: null,
        addUser: false,
        user: null,
        name: null,
        email: null,
        phone: null,
      },
      isLoadingCreateAppointment: false,
      isLoadingAppointmentConnections: false,
      newAppointment: {
        connection: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        type: 'cedula',
        document: null,
        cities: [],
        city: null,
        sectors: [],
        sector: null,
        medicalCenters: [],
        medicalCenter: null,
        specialties: [],
        specialty: null,
        date: null,
        schedules: [],
        schedule: null,
        doctors: [],
        doctor: null,
        addProduct: true,
      },
      probability: null,
      channel: null,
      location: null,
      documentReceptor: null,
      selectedDocument: null,
      selectedContactLead: null,
      isShow: true,
      showModal: false,
      modalStatus: null,
      selected: '',
      isOpen: false,
      type: 'active',
      selectedTab: 'info',
      selectedDatesTab: 'list-appointments',
      selectedStatusType: 'active',
      isLoadingLeads: false,
      isLoadingLeadsUsers: false,
      isLoadingLead: false,
      isLoadingMore: false,
      isLoadingCompanies: false,
      isLoadingStatuses: false,
      isLoadingAreas: false,
      isLoadingLeadEvents: false,
      isLoadingLeadDocuments: false,
      isLoadingLeadOptins: false,
      isLoadingLeadProducts: false,
      isLoadingLeadConversations: false,
      isLoadingLeadAppointments: false,
      isLoadingLeadLeads: false,
      isLoadingLeadHistory: false,
      isLoadingLeadChat: false,
      isLoadingCreateLead: false,
      isLoadingConversationNotes: false,
      showNoteInterface: false,
      showDeleteModal: false,
      showArchiveModal: false,
      showActiveModal: false,
      showCreateModal: false,
      showEmailModal: false,
      showDocumentModal: false,
      showDeleteFileModal: false,
      showDeleteContactLeadModal: false,
      activeStatusModal: null,
      isOpenA: false,
      note: '',
      saving: false,
      selectedLeads: [],
      text: '',
      order: 'DESC',
      filter: 'all',
      allSelected: false,
      search: null,
      editName: false,
      selectedUser: 0,
      selectedStatus: null,
      dateValue: ref({
        startDate: null,
        endDate: null,
      }),
      formatter: ref({
        date: 'DD-MM-YYYY',
        month: 'MMM',
      }),
      options: ref({
        shortcuts: {
          today: 'Hoy',
          yesterday: 'Ayer',
          past: (period) => `últimos ${period} días`,
          currentMonth: 'este mes',
          pastMonth: 'Mes pasado',
        },
        footer: {
          apply: 'Aplicar',
          cancel: 'Cancelar',
        },
      }),
      cssProps: {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/bg-chat.jpg')})`,
      },
      cssProps2: {
        // eslint-disable-next-line global-require
        background: 'white',
      },
      props: {
        msg: String,
      },
    };
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeLead) {
          this.getLeadEvents();
          this.getLeadHistory();
          this.getNotes();
          this.getLeadConversations();
          this.getLeadAppointments();
          this.getLeadDocuments();
          this.getLeadOptins();
          this.getLeadProducts();
        }
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
      this.getLeads();
      this.getLeadsUsers();
      this.getStatuses();
    },
  },
  methods: {
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) this.files.splice(index, 1);
    },
    openFilePicker() {
      const element = this.$refs.fileInput;
      element.click();
    },
    onInputChange(e) {
      this.addFiles(e.target.files);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    addFiles(newFiles) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      this.files = this.files.concat(newUploadableFiles);
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },
    sendFiles() {
      console.log('files', this.files);
      try {
        this.files.forEach((file) => {
          console.log('file', file);
          this.uploadFile(file);
        });

        this.files = [];
        this.showDocumentModal = false;
        setTimeout(() => {
          this.getLeadDocuments();
        }, 3000);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'hubo un error',
        });
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      this.isLoading = true;
      console.log('formData', formData);
      this.axios.post(
        this.filesUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files', data);
        this.$notify({
          type: 'success',
          title: 'documento almacenado',
          text: `Se almacenó el archivo ${file.file.name}`,
        });
      })
        .catch((e) => {
          console.log('error', e);
        });
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        params,
      );
    },
    setFields() {
      this.newLead.fields = [];
      console.log('this.newLead.form.fields', this.newLead.form.fields);
      this.newLead.form.fields.forEach((field) => {
        if (field.key !== 'nombre' && field.key !== 'email' && field.key !== 'name'
          && field.key !== 'phone' && field.key !== 'mail' && field.key !== 'telefono') {
          this.newLead.fields.push({
            key: field.key,
            name: field.name,
            type: field.type,
            options: field.options,
            data: null,
          });
        }
      });
      console.log('this.newLead.fields', this.newLead.fields);
    },
    openEmailModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.documentReceptor = null;
      this.showEmailModal = true;
    },
    openDeleteFileModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.showDeleteFileModal = true;
    },
    openDeleteContactLeadModal(lead) {
      this.selectedContactLead = lead;
      this.showDeleteContactLeadModal = true;
    },
    getPayment(text) {
      switch (text) {
        case 'transfer':
          return 'Transferencia Bancaria';
        case 'cash':
          return 'Efectivo';
        case 'credit_card':
          return 'Tarjeta de crédito';
        default:
          return text;
      }
    },
    deleteLeads() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedLeads,
      };
      this.$store.dispatch('deleteLeads', payload)
        .then((response) => {
          console.log('respuesta deleteLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads eliminados',
            text: success,
          });
          this.showDeleteModal = false;
          this.selectedLeads = [];
          this.getLeads();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getLeadsUsers();
        }, (error) => {
          console.log('error deleteLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteLeads', error);
        });
    },
    deleteContactLead() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: [this.selectedContactLead.uuid],
      };
      this.$store.dispatch('deleteLeads', payload)
        .then((response) => {
          console.log('respuesta deleteLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: `lead ${this.selectedContactLead.name} eliminado`,
            text: success,
          });
          this.showDeleteContactLeadModal = false;
          this.getLead(this.activeLead.uuid);
          this.getLeads();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getLeadsUsers();
          this.selectedContactLead = null;
        }, (error) => {
          console.log('error deleteLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteLeads', error);
        });
    },
    archiveLeads() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedLeads,
      };
      this.$store.dispatch('archiveLeads', payload)
        .then((response) => {
          console.log('respuesta archiveLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads archivados',
            text: success,
          });
          this.showArchiveModal = false;
          this.selectedLeads = [];
          this.getLeads();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getLeadsUsers();
        }, (error) => {
          console.log('error archiveLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error archiveLeads', error);
        });
    },
    activeLeads() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedLeads,
        status: this.activeStatusModal?.uuid,
      };
      this.$store.dispatch('activeLeads', payload)
        .then((response) => {
          console.log('respuesta activeLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads activados',
            text: success,
          });
          this.showActiveModal = false;
          this.selectedLeads = [];
          this.getLeads();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getLeadsUsers();
        }, (error) => {
          console.log('error activeLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error activeLeads', error);
        });
    },
    createLead() {
      const payload = {
        company: this.activeCompany.uuid,
        form: this.newLead.form.uuid,
        name: this.newLead.name,
        email: this.newLead.email,
        phone: this.newLead.phone,
        addProduct: this.newLead.addProduct,
        product: this.newLead.product?.uuid,
        addUser: this.newLead.addUser,
        user: this.newLead.user?.id,
      };
      const fields = {};
      this.newLead.fields.forEach((field) => {
        fields[field.name] = field.data;
      });
      payload.fields = fields;
      this.isLoadingCreateLead = true;
      this.$store.dispatch('createLead', payload)
        .then(
          (response) => {
            this.isLoadingCreateLead = false;
            console.log('response createLead', response);
            this.$notify({
              type: 'success',
              title: 'Lead creado',
              text: response.data.message,
            });
            this.getLeads();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getLeadsUsers();
            this.newLead = {
              form: null,
              fields: [],
              addProduct: false,
              category: null,
              product: null,
              addUser: false,
              user: null,
              name: null,
              email: null,
              phone: null,
            };
            this.showCreateModal = false;
          },
          (error) => {
            this.isLoadingCreateLead = false;
            console.log('error createLead', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateLead = false;
          console.log('error createLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignLeads() {
      const usersList = [];
      if (this.selectAssignUsers) {
        this.assignUsersList.forEach((user) => {
          if (user.selected) {
            usersList.push(user.id);
          }
        });
      }
      const payload = {
        company: this.activeCompany.uuid,
        selectUsers: this.selectAssignUsers,
        usersList,
      };
      console.log('payload', payload);
      this.isLoadingAssignAuto = true;
      this.$store.dispatch('assignAuto', payload)
        .then(
          (response) => {
            this.isLoadingAssignAuto = false;
            console.log('response assignAuto', response);
            this.$notify({
              type: 'success',
              title: 'Leads asignados',
              text: response.data.success,
            });
            this.getLeads();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getLeadsUsers();
            this.showAssignModal = false;
          },
          (error) => {
            this.isLoadingAssignAuto = false;
            console.log('error assignAuto', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignAuto', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignOneLeads() {
      const payload = {
        company: this.activeCompany.uuid,
        user: this.assignUserModal.uuid,
        ids: this.selectedLeads,
      };
      this.isLoadingAssign = true;
      this.$store.dispatch('assignOne', payload)
        .then(
          (response) => {
            this.isLoadingAssign = false;
            console.log('response assignOne', response);
            this.$notify({
              type: 'success',
              title: `Leads asignados a ${this.assignUserModal.name}`,
              text: response.data.success,
            });
            this.getLeads();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getLeadsUsers();
            this.isLoadingAssign = false;
            this.showAssignOneModal = false;
            this.selectedLeads = [];
            this.assignUserModal = null;
          },
          (error) => {
            this.isLoadingAssign = false;
            console.log('error assignOne', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignOne', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getProducts() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('products', payload)
        .then((response) => {
          console.log('respuesta products', response);
        }, (error) => {
          console.log('error products', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error products', error);
        });
    },
    getProductSections() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productSections', payload)
        .then((response) => {
          console.log('respuesta productSections', response);
        }, (error) => {
          console.log('error productSections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productSections', error);
        });
    },
    getProductCategories() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productCategories', payload)
        .then((response) => {
          this.categoriesCopy = this.companyCategories.map((category) => {
            // eslint-disable-next-line no-param-reassign
            category.open = false;
            category.products.map((product) => {
              // eslint-disable-next-line no-param-reassign
              product.selected = false;
              // eslint-disable-next-line no-param-reassign
              product.use_discount = false;
              // eslint-disable-next-line no-param-reassign
              product.quantity = 1;
              // eslint-disable-next-line no-param-reassign
              product.note = null;
              // eslint-disable-next-line no-param-reassign
              product.final_price = product.price;
              // eslint-disable-next-line no-param-reassign
              product.used_sessions = 0;
              return product;
            });
            return category;
          });
          console.log('respuesta productCategories', response);
        }, (error) => {
          console.log('error productCategories', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productCategories', error);
        });
    },
    save() {
      if (this.saving) return;
      console.log('guarda', this.activeLead);
      const payload = {
        lead: this.activeLead,
        company: this.activeCompany.uuid,
      };
      this.saving = true;
      this.$store.dispatch('updateLead', payload)
        .then(
          (response) => {
            this.saving = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'conversación actualizada',
              text: response.data.message,
            });
            this.getLeadEvents();
            this.getLeadHistory();
            this.getNotes();
            this.getLeadConversations();
            this.getLeadAppointments();
            this.getLeadDocuments();
            this.getLeadOptins();
            this.getLeadProducts();
            // this.getLeads();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getChannels();
            this.getEvents();
            this.getLeadsUsers();
          },
          (error) => {
            this.saving = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    goToConversation(uuid) {
      this.$router.push(`/chat?conversation=${uuid}`).catch(() => {
      });
    },
    goToAppointment(uuid) {
      this.$router.push(`/dates?date=${uuid}`).catch(() => {
      });
    },
    getConversation(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingConversation = true;
      this.$store.dispatch('conversation', payload)
        .then((response) => {
          // this.isLoadingConversation = false;
          console.log('respuesta conversation', response);
          // this.getMessages();
          // this.getLeads();
          // this.getSender(this.activeConversation.uuid);
          // this.getNotes();
          this.$router.push('/chat').catch(() => {
          });
        }, (error) => {
          // this.isLoadingConversation = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    updateSender() {
      if (this.savingSender) return;
      console.log('guarda', this.activeSender);
      const payload = {
        conversation: this.activeConversation,
        company: this.activeCompany.uuid,
        sender: this.activeSender,
      };
      this.savingSender = true;
      this.$store.dispatch('updateSender', payload)
        .then(
          (response) => {
            this.savingSender = false;
            console.log('response sender', response);
            this.$notify({
              type: 'success',
              title: 'contacto actualizado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.savingSender = false;
            console.log('error sender', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.savingSender = false;
          console.log('error sender', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setProbability(probability) {
      this.probability = probability;
      this.getLeads();
      this.getLeadsUsers();
      this.getStatuses();
    },
    setChannel(channel) {
      this.channel = channel;
      this.getLeads();
      this.getLeadsUsers();
      this.getStatuses();
    },
    setLocation(location) {
      this.location = location;
      this.getLeads();
      this.getLeadsUsers();
      this.getStatuses();
    },
    setStatusType(statusType) {
      this.selectedStatusType = statusType;
    },
    sendMessage(type) {
      if (this.isLoadingMessage) return;
      if (this.activeConversation.is_after) return;
      const payload = {
        body: this.text,
        type,
        id: this.activeConversation.uuid,
      };
      this.isLoadingMessage = true;
      this.$store.dispatch('sendMessage', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response message', response);
            this.text = null;
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    setUser(user) {
      if (this.selectedUser === user.id) {
        this.selectedUser = 0;
      } else {
        this.selectedUser = user.id;
      }
      this.getLeads();
      this.getStatuses();
    },
    selectStatus(status) {
      this.modalStatus = status;
      this.showModal = true;
    },
    setStatus(status) {
      if (this.selectedStatus) {
        if (this.selectedStatus === status.id) {
          this.selectedStatus = null;
        } else {
          this.selectedStatus = status.id;
        }
      } else {
        this.selectedStatus = status.id;
      }
      this.getLeads();
      this.getLeadsUsers();
    },
    selectTab(tab) {
      this.selectedTab = tab;
      if (tab === 'appointments') {
        this.selectedDatesTab = 'list-appointments';
      }
      if (tab === 'create-appointment') {
        if (this.appointmentConnections.length === 1) {
          const [firstConnection] = this.appointmentConnections;
          this.newAppointment.connection = firstConnection;
          this.getAppointmentsCities();
          this.newAppointment.name = this.activeLead.name;
          this.newAppointment.phone = this.activeLead.phone;
          this.newAppointment.email = this.activeLead.email;
        }
      }
    },
    selectDatesTab(tab) {
      this.selectedDatesTab = tab;
      if (tab === 'create-appointment') {
        this.newAppointment.name = this.activeLead.name;
        this.newAppointment.phone = this.activeLead.phone;
        this.newAppointment.email = this.activeLead.email;
        this.newAppointment.document = this.activeLead.contact.document;
        this.categoriesCopy = this.companyCategories.map((category) => {
          // eslint-disable-next-line no-param-reassign
          category.open = false;
          category.products.map((product) => {
            // eslint-disable-next-line no-param-reassign
            product.selected = false;
            // eslint-disable-next-line no-param-reassign
            product.use_discount = false;
            // eslint-disable-next-line no-param-reassign
            product.quantity = 1;
            // eslint-disable-next-line no-param-reassign
            product.note = null;
            // eslint-disable-next-line no-param-reassign
            product.final_price = product.price;
            // eslint-disable-next-line no-param-reassign
            product.used_sessions = 0;
            return product;
          });
          return category;
        });
        if (this.appointmentConnections.length === 1) {
          const [firstConnection] = this.appointmentConnections;
          this.newAppointment.connection = firstConnection;
          this.getAppointmentsCities();
        }
      }
    },
    changeOrder(direction) {
      this.order = direction;
      this.getLeads();
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedLeads = [];
      } else {
        this.selectedLeads = this.leads.map((lead) => lead.uuid);
      }
      this.allSelected = !this.allSelected;
    },
    setEmoji(emoji) {
      this.text += emoji.native;
    },
    selectLead(lead) {
      if (!this.isLoadingLead) {
        this.selectedTab = 'info';
        this.getLead(lead);
      }
    },
    selectGroupLead(lead) {
      if (this.selectedLeads.includes(lead.uuid)) {
        const index = this.selectedLeads.indexOf(lead.uuid);
        this.selectedLeads.splice(index, 1);
      } else {
        this.selectedLeads.push(lead.uuid);
      }
    },
    scrollDown() {
      const element = document.getElementById('messages_container');
      element.scrollTop = element.scrollHeight;
    },
    closeLead() {
      this.$store.dispatch('setActiveLead', null)
        .then((response) => {
          console.log('respuesta', response);
          this.addHashToLocation('/leads');
        }, (error) => {
          console.log('error', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    changedCompany() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeLead();
      this.getLeadsUsers();
      this.getLeadsUsersList();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
      this.getEvents();
      this.getIntegrations();
      this.getLeads();
      this.getForms();
      this.getConnections();
      this.getSaleProbabilities();
      this.getProductCategories();
      this.getProductSections();
      this.getProducts();
      this.getLeadNotifications();
      this.getMessageNotifications();
      this.getAppointmentConnections();
    },
    changedForm() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeLead();
      this.getLeadsUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getLeads();
    },
    changedArea() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeLead();
      this.getLeadsUsers();
      this.getStatuses();
      this.getLeads();
    },
    setToken() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then(
          (response) => {
            console.log('response token', response);
            this.getCompanies();
            this.getActualToken();
          },
          (error) => {
            console.log('error token', error);
          },
        ).catch((error) => {
          console.log('error token', error);
        });
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getLeads();
          this.getLeadsUsers();
          this.getLeadsUsersList();
          this.getForms();
          this.getConnections();
          this.getSaleProbabilities();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getChannels();
          this.getEvents();
          this.getIntegrations();
          this.getProductCategories();
          this.getProductSections();
          this.getProducts();
          this.getLeadNotifications();
          this.getMessageNotifications();
          this.getAppointmentConnections();
          const id = this.$route.query.lead;
          if (id) {
            this.selectLead(id);
          }
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    refresh() {
      this.getLeads();
      this.getLeadsUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
      this.getEvents();
    },
    getLeads() {
      // TODO: send pagination
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
      };
      console.log('payload leads', payload);
      this.isLoadingLeads = true;
      this.$store.dispatch('leads', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingLeads = false;
        }, (error) => {
          console.log('error conversations', error);
          this.isLoadingLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    getLeadNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingLeadNotifications = true;
      this.$store.dispatch('leadNotifications', payload)
        .then((response) => {
          console.log('respuesta leadNotifications', response);
          this.isLoadingLeadNotifications = false;
        }, (error) => {
          console.log('error leadNotifications', error);
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadNotifications', error);
        });
    },
    getMessageNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingMessageNotifications = true;
      this.$store.dispatch('messageNotifications', payload)
        .then((response) => {
          console.log('respuesta messageNotifications', response);
          this.isLoadingMessageNotifications = false;
        }, (error) => {
          console.log('error messageNotifications', error);
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messageNotifications', error);
        });
    },
    toggleCategory(categoryId) {
      // Toggle para abrir/cerrar cada categoría
      // eslint-disable-next-line max-len
      this.categoriesCopy = this.categoriesCopy.map((category) => (category.slug === categoryId ? {
        ...category,
        open: !category.open,
      } : category));
    },
    loadMore() {
      if (this.isLoadingMore) return;
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        url: this.nextPageUrl,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
      };
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageLeads', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error conversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    getConnections() {
      // TODO: send pagination
      this.isLoadingConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('connections', payload)
        .then((response) => {
          this.isLoadingConnections = false;
          console.log('respuesta connections', response);
        }, (error) => {
          this.isLoadingConnections = false;
          console.log('error connections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error connections', error);
        });
    },
    getLeadsUsers() {
      // TODO: send pagination

      this.isLoadingLeadsUsers = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('leadUsers', payload)
        .then((response) => {
          this.isLoadingLeadsUsers = false;
          console.log('respuesta leadUsers', response);
        }, (error) => {
          this.isLoadingLeadsUsers = error;
          console.log('error leadUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadsUsers = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsers', error);
        });
    },
    getLeadsUsersList() {
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
      };
      this.$store.dispatch('leadUsersList', payload)
        .then((response) => {
          console.log('respuesta leadUsersList', response);
          this.assignUsersList = this.leadsUsersList.map((user) => {
            const usr = user;
            usr.selected = false;
            return usr;
          });
        }, (error) => {
          console.log('error leadUsersList', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsersList', error);
        });
    },
    createField() {
      const field = {
        data: this.newField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.newField.label,
        label: this.newField.label,
      };
      this.activeLead.fields.push(field);
      this.showFieldInterface = false;
      this.newField = {
        label: null,
        data: null,
      };
    },
    addNewField() {
      const field = {
        data: this.createNewField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.createNewField.name,
        label: this.createNewField.name,
        name: this.createNewField.name,
      };
      this.newLead.fields.push(field);
      this.showCreateFieldInterface = false;
      this.createNewField = {
        name: null,
        label: null,
        data: null,
      };
    },
    deleteField(key) {
      this.filteredFields.splice(key, 1);
    },
    createNote() {
      const payload = {
        note: this.note,
        lead: this.activeLead.uuid,
      };
      this.isLoadingCreateNote = true;
      this.$store.dispatch('createLeadNote', payload)
        .then(
          (response) => {
            this.isLoadingCreateNote = false;
            this.showNoteInterface = false;
            this.note = '';
            console.log('response createNote', response);
            this.$notify({
              type: 'success',
              title: 'Nota creada',
              text: response.data.message,
            });
            this.getNotes();
          },
          (error) => {
            this.isLoadingCreateNote = false;
            console.log('error createNote', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateNote = false;
          console.log('error createNote', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendEmail() {
      const payload = {
        document: this.selectedDocument.uuid,
        email: this.documentReceptor,
        lead: this.activeLead.uuid,
      };
      this.isLoadingLead = true;
      this.$store.dispatch('sendEmail', payload)
        .then(
          (response) => {
            this.isLoadingLead = false;
            this.showEmailModal = false;
            this.selectedDocument = null;
            this.documentReceptor = null;
            console.log('response sendEmail', response);
            this.$notify({
              type: 'success',
              title: 'Documento enviado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingLead = false;
            console.log('error sendEmail', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingLead = false;
          console.log('error sendEmail', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    deleteFile() {
      const payload = {
        document: this.selectedDocument.uuid,
        lead: this.activeLead.uuid,
        company: this.activeCompany.uuid,
      };
      console.log('payload', payload);
      this.isLoadingLead = true;
      this.$store.dispatch('deleteFile', payload)
        .then(
          (response) => {
            this.isLoadingLead = false;
            this.showDeleteFileModal = false;
            console.log('response deleteFile', response);
            this.$notify({
              type: 'success',
              title: 'Documento eliminado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingLead = false;
            console.log('error deleteFile', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingLead = false;
          console.log('error deleteFile', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getNotes() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingConversationNotes = true;
      this.$store.dispatch('leadNotes', payload)
        .then((response) => {
          this.isLoadingConversationNotes = false;
          console.log('respuesta leadNotes', response);
        }, (error) => {
          this.isLoadingConversationNotes = false;
          console.log('error leadNotes', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationNotes = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadNotes', error);
        });
    },
    getStatuses() {
      // TODO: send pagination
      this.isLoadingStatuses = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        probability: this.probability?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('statuses', payload)
        .then((response) => {
          this.isLoadingStatuses = false;
          console.log('respuesta statuses', response);
        }, (error) => {
          this.isLoadingStatuses = false;
          console.log('error statuses', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingStatuses = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error statuses', error);
        });
    },
    getAreas() {
      // TODO: send pagination
      this.isLoadingAreas = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('areas', payload)
        .then((response) => {
          this.isLoadingAreas = false;
          console.log('respuesta areas', response);
        }, (error) => {
          this.isLoadingAreas = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingAreas = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error areas', error);
        });
    },
    getLocations() {
      // TODO: send pagination
      this.isLoadingLocations = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('locations', payload)
        .then((response) => {
          this.isLoadingLocations = false;
          console.log('respuesta isLoadingLocations', response);
        }, (error) => {
          this.isLoadingLocations = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingLocations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error isLoadingLocations', error);
        });
    },
    getChannels() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
      };
      this.$store.dispatch('channels', payload)
        .then((response) => {
          console.log('respuesta channels', response);
        }, (error) => {
          console.log('error channels', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error channels', error);
        });
    },
    getEvents() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
        start: new Date(),
        end: new Date(),
      };
      this.$store.dispatch('events', payload)
        .then((response) => {
          console.log('respuesta events', response);
        }, (error) => {
          console.log('error events', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error events', error);
        });
    },
    getForms() {
      // TODO: send pagination
      this.isLoadingForms = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('forms', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta forms', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error forms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error forms', error);
        });
    },
    getSaleProbabilities() {
      // TODO: send pagination
      this.isLoadingForms = true;
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('probabilities', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta probabilities', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error probabilities', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error probabilities', error);
        });
    },
    getIntegrations() {
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('integrations', payload)
        .then((response) => {
          console.log('respuesta integrations', response);
          response.data.success.forEach((integration) => {
            if (integration.type === 'vtiger' || integration.type === 'teojama') {
              this.getIntegrationUsers(integration.type, integration.uuid);
            }
          });
        }, (error) => {
          console.log('error integrations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrations', error);
        });
    },
    getIntegrationUsers(type, integration) {
      console.log('type', type);
      console.log('integration', integration);
      const payload = {
        company: this.activeCompany.uuid,
        type,
        integration,
      };
      console.log('payload', payload);
      this.$store.dispatch('integrationUsers', payload)
        .then((response) => {
          console.log('respuesta integrationUsers', response);
        }, (error) => {
          console.log('error integrationUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrationUsers', error);
        });
    },
    getNumbers() {
      this.$store.dispatch('conversations', this.activeCompany.uuid)
        .then((response) => {
          console.log('respuesta', response);
          const { conversations } = response.data.success;
          console.log('conversations', conversations);
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    closeModalUpdate(uuid) {
      this.showModal = false;
      this.getLead(uuid);
    },
    getLead(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      this.addHashToLocation(`/leads?lead=${uuid}`);
      this.isLoadingLead = true;
      this.$store.dispatch('lead', payload)
        .then((response) => {
          this.isLoadingLead = false;
          console.log('respuesta lead', response);
          this.getLeadEvents();
          this.getLeadHistory();
          this.getNotes();
          this.getLeadConversations();
          this.getLeadAppointments();
          this.getLeadDocuments();
          this.getLeadOptins();
          this.getLeadProducts();
        }, (error) => {
          this.isLoadingLead = false;
          console.log('error lead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLead = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error lead', error);
        });
    },
    getLeadLeads() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadLeads = true;
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          this.isLoadingLeadLeads = false;
          console.log('respuesta leadLeads', response);
        }, (error) => {
          this.isLoadingLeadLeads = false;
          console.log('error leadLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    getLeadEvents() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadEvents = true;
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          this.isLoadingLeadEvents = false;
          console.log('respuesta leadEvents', response);
        }, (error) => {
          this.isLoadingLeadEvents = false;
          console.log('error leadEvents', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadEvents = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadEvents', error);
        });
    },
    getLeadProducts() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadProducts = true;
      this.$store.dispatch('leadProducts', payload)
        .then((response) => {
          this.isLoadingLeadProducts = false;
          console.log('respuesta leadProducts', response);
        }, (error) => {
          this.isLoadingLeadProducts = false;
          console.log('error leadProducts', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadProducts = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadProducts', error);
        });
    },
    getLeadDocuments() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadDocuments = true;
      this.$store.dispatch('leadDocuments', payload)
        .then((response) => {
          this.isLoadingLeadDocuments = false;
          console.log('respuesta leadDocuments', response);
        }, (error) => {
          this.isLoadingLeadDocuments = false;
          console.log('error leadDocuments', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadDocuments = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadDocuments', error);
        });
    },
    getLeadOptins() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadOptins = true;
      this.$store.dispatch('leadOptins', payload)
        .then((response) => {
          this.isLoadingLeadOptins = false;
          console.log('respuesta leadOptins', response);
        }, (error) => {
          this.isLoadingLeadOptins = false;
          console.log('error leadOptins', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadOptins = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadOptins', error);
        });
    },
    getLeadConversations() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadConversations = true;
      this.$store.dispatch('leadConversations', payload)
        .then((response) => {
          this.isLoadingLeadConversations = false;
          console.log('respuesta leadConversations', response);
        }, (error) => {
          this.isLoadingLeadConversations = false;
          console.log('error leadConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadConversations', error);
        });
    },
    getLeadAppointments() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadAppointments = true;
      this.$store.dispatch('leadAppointments', payload)
        .then((response) => {
          this.isLoadingLeadAppointments = false;
          console.log('respuesta leadAppointments', response);
        }, (error) => {
          this.isLoadingLeadAppointments = false;
          console.log('error leadAppointments', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadAppointments = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadAppointments', error);
        });
    },
    getLeadHistory() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.isLoadingLeadHistory = true;
      this.$store.dispatch('leadHistory', payload)
        .then((response) => {
          this.isLoadingLeadHistory = false;
          console.log('respuesta leadHistory', response);
        }, (error) => {
          this.isLoadingLeadHistory = false;
          console.log('error leadHistory', error);
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: error?.data?.message,
          // });
        })
        .catch((error) => {
          this.isLoadingLeadHistory = false;
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: 'hubo un error',
          // });
          console.log('error leadHistory', error);
        });
    },
    setType(type) {
      this.type = type;
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.getLeadsUsers();
      this.getStatuses();
      this.getLeads();
    },
    setOnMessage() {
      onMessage(this.$messaging, (payload) => {
        if (payload.data.for === 'user') {
          this.$notify({
            type: 'success',
            title: payload.notification.title,
            text: payload.notification.body,
          });
        }
        this.getLeads();
        this.getMessageNotifications();
        console.log('Message received. ', payload);
        // if (this.activeConversation) {
        //   if (this.activeConversation.uuid === payload.data.conversation_id) {
        //     this.selectedTab = 'chat';
        //     setTimeout(() => {
        //       this.getMessagesAgain();
        //     }, 100);
        //   }
        // } else {
        //   console.log('no esta activa');
        //   console.log(payload.data.conversation_id);
        //   // TODO: check if is in correct company, if no set the correct company
        //   this.getLead(payload.data.conversation_id);
        // }
      });
    },
    getKeys(obj) {
      return Object.keys(obj);
    },
    checkIfExist(items, item) {
      return items.includes(item);
    },
    filterVtigerUser(id, type) {
      const vt = this.companyIntegrations.find((int) => int.type === type);
      console.log('vt', vt);
      if (vt) {
        if (this.integrationUsers[type]) {
          const user = this.integrationUsers[type].filter((usr) => usr.id === id)[0];
          if (user) {
            return `${user.first_name} ${user.last_name}`;
          }
        }
        return `El usuario ya no existe en ${type}`;
      }
      return 'No hay integraciones';
    },
    sourceLead(lead) {
      if (lead) {
        let url = '';
        switch (lead.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            // eslint-disable-next-line global-require
            url = require('@/assets/facebook-leadgen.png');
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'leadbox-dates':
            // eslint-disable-next-line global-require
            url = require('@/assets/dates.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeLead.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeLead.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },
    getActualToken() {
      getToken(this.$messaging, {
        vapidKey: 'BLfWeuuIdNsHrWx-2XIUiXfn_PEWjUMI6oUwLRSerdWw4JmKxE6O22nxcJ6dO60ZPahGMSBlyUTasq1OkgYogdc',
      }).then((currentToken) => {
        if (currentToken) {
          console.log('token', currentToken);
          this.$store.dispatch('tokens', [currentToken])
            .then((response) => {
              console.log('respuesta', response);
              this.setOnMessage();
            }, (error) => {
              console.log('status', error);
              this.$notify({
                type: 'error',
                title: 'Error',
                text: error?.data?.message,
              });
            })
            .catch((error) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'hubo un error',
              });
              console.log('error', error);
            });
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    },
    // appointments
    getAppointmentConnections() {
      // TODO: send pagination
      this.isLoadingAppointmentConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('appointmentConnections', payload)
        .then((response) => {
          this.isLoadingAppointmentConnections = false;
          console.log('respuesta Appointmentconnections', response);
          console.log('respuesta2 Appointmentconnections', this.appointmentConnections);
        }, (error) => {
          this.isLoadingAppointmentConnections = false;
          console.log('error Appointmentconnections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error Appointmentconnections', error);
        });
    },
    getAppointmentsCities() {
      if (!this.newAppointment.connection) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
      };
      this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsCities', payload)
        .then((response) => {
          this.isLoadingAppointmentsCities = false;
          console.log('respuesta AppointmentsCities', response);
          this.newAppointment.cities = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsMedicalCenters', response);
          const medicalCenters = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
          if (medicalCenters.length > 10) {
            this.getAppointmentsSectors();
          } else {
            this.newAppointment.medicalCenters = medicalCenters;
          }
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsDoctorsAndSpecialties() {
      this.getAppointmentsDoctors();
      this.getAppointmentsSpecialties();
      // esto
    },
    getAppointmentsDoctors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsDoctors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsDoctors', response);
          this.newAppointment.doctors = response.data;
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialties() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialties', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialties', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialtiesByDoctor() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        doctor_id: this.newAppointment.doctor?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialtiesByDoctor', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialtiesByDoctor', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectors', response);
          this.newAppointment.sectors = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectorMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.sector) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        sector: this.newAppointment.sector,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectorMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectorMedicalCenters', response);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = response.data;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        });
    },
    getAppointmentsSchedules() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      if (!this.newAppointment.date) {
        return;
      }
      console.log('this.company', this.activeCompany.uuid);
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        medical_center_id: this.newAppointment.medicalCenter?.id,
        date: this.parsedAppointmentDate,
        endpoint: 'schedules',
      };
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.doctor_id = this.newAppointment.doctor?.id;
        payload.endpoint = 'schedules-aura';
      }
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSchedules', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSchedules', response);
          this.newAppointment.schedules = response.data;
          this.newAppointment.schedule = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        });
    },
    createAppointment() {
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id?.toString(),
        medical_center_id: this.newAppointment.medicalCenter?.id?.toString(),
        date: this.parsedAppointmentDate,
        schedule: `${this.newAppointment.schedule?.startLabel
        }-${this.newAppointment.schedule?.endLabel}`,
        name: this.newAppointment.name,
        email: this.newAppointment.email,
        phone: this.newAppointment.phone,
        number: this.newAppointment.number,
        document: this.newAppointment.document,
        lead_id: this.activeLead?.uuid,
        endpoint: 'appointment',
      };
      if (this.newAppointment.doctor) {
        payload.doctor_id = this.newAppointment.doctor?.id;
      }
      if (this.newAppointment.specialty) {
        payload.specialty_id = this.newAppointment.specialty?.id;
      }
      const products = [];
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            products.push(product);
          }
        });
      });
      payload.products = products;
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.endpoint = 'appointment-aura';
      }
      console.log('payload creatre appointment', payload);
      this.isLoadingCreateAppointment = true;
      this.$store.dispatch('createAppointment', payload)
        .then(
          (response) => {
            this.isLoadingCreateAppointment = false;
            console.log('response createAppointment', response);
            this.$notify({
              type: 'success',
              title: 'Cita creada ',
            });
            this.getLead(this.activeLead.uuid);
            this.newAppointment = {
              connection: null,
              name: null,
              surname: null,
              email: null,
              phone: null,
              type: 'cedula',
              document: null,
              cities: [],
              city: null,
              sectors: [],
              sector: null,
              medicalCenters: [],
              medicalCenter: null,
              specialties: [],
              specialty: null,
              date: null,
              schedules: [],
              schedule: null,
              doctors: [],
              doctor: null,
              addProduct: true,
            };
            this.selectedTab = 'appointments';
          },
          (error) => {
            this.isLoadingCreateAppointment = false;
            console.log('error createAppointment', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateAppointment = false;
          console.log('error createAppointment', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    /// space
    /// space
    /// space
    /// space
    /// space
  },
  computed: {
    total_products() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            if (product.use_discount) {
              total += product.discount_price * product.quantity;
            } else {
              total += product.price * product.quantity;
            }
          }
        });
      });
      return total;
    },
    total_dates() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            total += product.number_dates * product.quantity;
          }
        });
      });
      return total;
    },
    filteredFields() {
      if (this.activeLead) {
        return this.activeLead.fields.filter((field) => field.is_visible);
      }
      return [];
    },
    filesUrl() {
      if (this.activeLead) {
        return `https://leadbox.ec/api/v1/leads/${this.activeLead.uuid}/uploadFile`;
      }
      return '';
    },
    companyCategories() {
      return this.$store.state.productCategories;
    },
    source() {
      if (this.activeLead) {
        let url = '';
        switch (this.activeLead.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            switch (this.activeLead.platform) {
              case 'ig':
              case 'in':
                // eslint-disable-next-line global-require
                url = require('@/assets/logo-instagram.png');
                break;
              case 'fb':
              default:
                // eslint-disable-next-line global-require
                url = require('@/assets/facebook-leadgen.png');
                break;
            }
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'leadbox-dates':
            // eslint-disable-next-line global-require
            url = require('@/assets/dates.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeLead.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeLead.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },

    companies() {
      return this.$store.state.companies;
    },
    statuses() {
      return this.$store.state.statuses;
    },
    activeStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'assigned' || status.type === 'initial');
      }
      return [];
    },
    closedStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'final_positive' || status.type === 'final_negative');
      }
      return [];
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeForm() {
      return this.$store.state.activeForm;
    },
    activeArea() {
      return this.$store.state.activeArea;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    channels() {
      return this.$store.state.channels;
    },
    messages() {
      return this.$store.state.conversationMessages;
    },
    leads() {
      return this.$store.state.leads;
    },
    total() {
      return this.$store.state.total;
    },
    conversations() {
      return this.$store.state.conversations;
    },
    activeLead() {
      return this.$store.state.activeLead;
    },
    appointmentConnections() {
      return this.$store.state.appointmentConnections;
    },
    leadsUsers() {
      return this.$store.state.leadsUsers;
    },
    leadsUsersList() {
      return this.$store.state.leadsUsersList;
    },
    locations() {
      return this.$store.state.locations;
    },
    forms() {
      return this.$store.state.forms;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url;
    },
    activeSender() {
      return this.$store.state.activeSender;
    },
    leadEvents() {
      return this.$store.state.leadEvents;
    },
    leadLeads() {
      return this.$store.state.leadLeads;
    },
    leadHistories() {
      return this.$store.state.leadHistories;
    },
    leadConversations() {
      return this.$store.state.leadConversations;
    },
    leadAppointments() {
      return this.$store.state.leadAppointments;
    },
    leadDocuments() {
      return this.$store.state.leadDocuments;
    },
    leadOptins() {
      return this.$store.state.leadOptins;
    },
    leadProducts() {
      return this.$store.state.leadProducts;
    },
    notes() {
      return this.$store.state.leadNotes;
    },
    areas() {
      return this.$store.state.areas;
    },
    probabilities() {
      return this.$store.state.probabilities;
    },
    companyIntegrations() {
      return this.$store.state.companyIntegrations;
    },
    integrationUsers() {
      return this.$store.state.integrationUsers;
    },
    parsedAppointmentDate() {
      if (this.newAppointment.date) {
        const fechaObjeto = new Date(`${this.newAppointment.date}T00:00:00-05:00`);
        // Obtener los componentes de la fecha
        const dia = fechaObjeto.getDate().toString().padStart(2, '0');
        const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
        const year = fechaObjeto.getFullYear();
        // Formatear la fecha en el formato 'DD/MM/YYYY'
        return `${dia}/${mes}/${year}`;
      }
      return '';
    },
    images() {
      if (this.activeLead) {
        if (this.activeLead.images) {
          return JSON.parse(this.activeLead.images);
        }
      }
      return [];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.acor .vcp__body-content {
  background: #f3f4f6 !important;
}

.acor .vcp__header-icon > span[data-v-64321428] {
  color: #4b4e4e !important;
}

.vcpg[data-v-63943e43] {
  border: none !important;
  border-radius: 0px !important;
}

.bg-header {
  background-color: #353E4B;
}

.vcp__header-icon {
  color: #bcc8ca;
}

.text-left {
  text-align: left;
}

.bg-green-720 {
  background-color: #2A6F7F !important;
}

.bg-indigo-600 {
  background: #205662 !important;
}

.hover\:bg-gray-500:hover {
  background-color: #2a6f7f7a;
  border-radius: 5px;
}

.flex.m-1.bg-white.p-2.cursor-pointer.border-r-4 {
  border-right: 4px solid #22d3c5;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100.bg-sky-400.border-sky-400.text-white {
  color: #ffffff;
  background-image: -webkit-gradient(linear, left top, right top, from(#00BFBC), to(#009DB2));
  background-image: linear-gradient(to right, #00BFBC, #009DB2);
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100 {
  color: white;
  background-color: #EAF2F9;
  border-color: #EAF2F9;
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

.btn-states {
  border-radius: 7px;
  min-width: 125px;
  max-width: 145px;
  color: #333;
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  border-color: #5bc0de;
}

.btn-states:hover {
  background: #5bc0de;
  transition: 0.7s;
}

.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

button.bg-sky-600 {
  background-color: #2A6F7F !important;
}

.border-leadbox-600 {
  border-color: #2A6F7F !important;
}

.acor .vcpg[data-v-63943e43] .vcp__header {
  background-color: #f9f6f6 !important;
  border: 1px solid #5b5858 !important;
  margin: 10px 0px !important;
  border-radius: 5px;
}

.acor .vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #bfc8ca !important;
}

.vcpg[data-v-63943e43] .vcp__header {
  background-color: #353e4b !important;
}

.vcpg[data-v-63943e43] .vcp:not(:first-of-type) .vcp__header {
  border-top: 1px solid #4a4e50 !important;
}

.vcp__body-content {
  background: #2c3646;
}

.icons-float svg {
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 100%;
}

.icons-float svg:hover {
  background: #2a6f7f;
}

.bg-hover {
  background: #2a6f7f;
}

.bg-email {
  background: #fbd123;
}

.bg-phone {
  background: #0673f8;
}

.bg-phone-issabel {
  background: #7f0ac4;
}

.bg-chat {
  background: #09ce0d;
}

.icono-email {
  background: #fbd123;
  color: #fff;
}

.icono-phone {
  background: #0673f8;
  color: #fff;
}

.icono-chat {
  background: #09ce0d;
  color: #fff;
}

.icons-float {
  z-index: 99;
  text-align: center;
  display: flex;
}

.notShown {
  visibility: hidden !important;
}

.sidebar-menu.test-sidebar.hidden {
  max-width: 40px;
}

.vcpg {
  border-bottom: none !important;
  border-top: solid 1px #75777a !important;
}

.vcp__header-icon {
  color: white;
}

/*Scroll*/
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

section.vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #2a6f7f !important;
  border: none !important;
}

.vcpg {
  border-left: none !important;
}

.border-leadbox-900 {
  border-color: #22d3c5;
}

.text-tabs {
  line-height: 4px;
}

@media (max-width: 600px) {
  .test-sidebar {
    overflow-y: auto;
  }

  .test-sidebar::-webkit-scrollbar-thumb {
    background-color: #22d3c5;
  }

  .test-sidebar::-webkit-scrollbar-track {
    background: #fff;
  }

  .test-sidebar::-webkit-scrollbar {
    width: 5px;
  }

  .area-message {
    left: -115px;
  }

  .area-notification {
    left: -71px;
  }

  .vcp__header[data-v-64321428] {
    padding: 0px !important;
  }

  .vcp__body-content[data-v-64321428] {
    padding: 12px 6px !important;
  }

  .scroll-states {
    overflow-x: auto;
  }

  .title-header {
    font-size: 20px;
  }

  .filter-date {
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 11px;
    margin-right: 0px;
    border: none;
  }

  button.mr-1.focus\:outline-none.text-litepie-secondary-400.dark\:text-opacity-70.rounded-md {
    margin-right: 0px;
  }
}
</style>
