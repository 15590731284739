<template>
  <div class="flex m-2 bg-white p-1 cursor-pointer border-gray-400 rounded-md"
       v-bind:class="{
    'border-r-4' : conversation.uuid === active?.uuid,
    'bg-orange-100' : !conversation.has_lead,
    'bg-amber-100' : conversation.awarded || conversation.week_awarded,
    }">
    <div class="md:w-8 w-6 my-4 rounded-full">
      <!--      eslint-disable-next-line-->
      <input type="checkbox" class="my-5 w-5" v-model="isSelected" @change="checkConversation()">
    </div>
    <!--      eslint-disable-next-line-->
    <div class="flex-grow p-1 w-full" @click="selectConversation()">
      <div class="flex text-sm justify-between w-full break-words">
        <div><b>{{ conversation.name }}</b></div>
      </div>
      <div class="flex">
        <img class="w-7 h-7 rounded-lg" :src="source" alt="whatsapp">
        <div class="flex-grow">
          <div class="flex text-sm justify-between m-2 my-1 break-words">
            <div>
              {{ conversation.form? conversation.form.name :
              conversation.connection? conversation.connection.name:''}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex text-xs justify-between m-1 break-words"
           v-if="conversation.connection_phone_number">
        <div><b>Linea: </b>{{ conversation.connection_phone_number }}</div>
      </div>
      <div class="flex text-xs justify-between m-1 break-words" v-if="conversation.service">
        <div><b>Servicio: </b>{{ conversation.service }}</div>
      </div>
      <div v-if="conversation.user" class="flex text-xs justify-between m-1 break-words">
        <div><b>Asignado a: </b>{{ conversation.user?.name }}</div>
      </div>
      <div v-if="conversation.status" class="flex text-xs justify-between m-1 break-words">
        <div><b>Estado: </b>{{ conversation.status?.name }}</div>
      </div>
      <div v-if="conversation.referral_id" class="flex text-xs justify-between m-1 break-words">
        <div><b>Anuncio WhatsApp: </b>
          {{ conversation.referral_headline }}</div>
      </div>
      <div v-if="conversation.campaign_name" class="flex text-xs justify-between m-1 break-words">
        <div><b>Campaña: </b>
          {{ conversation.campaign_name }}</div>
      </div>
      <div class="flex text-xs justify-between m-1 break-words">
        <div>{{ date }}</div>
      </div>
      <div v-if="conversation.awarded "
           class="flex text-sm justify-between m-1 break-words">
        <div>
          <SparklesIcon class="w-5" aria-hidden="true" style="display: inline-block"/>
          <b >Premiada</b>
        </div>
      </div>
      <div v-if="conversation.week_awarded"
           class="flex text-sm justify-between m-1 break-words">
        <div>
          <SparklesIcon class="w-5" aria-hidden="true" style="display: inline-block"/>
          <b >Premiada Semanal</b>
        </div>
      </div>
    </div>
    <div class="flex mx-1 items-center" v-if="conversation.new_messages_count > 0">
      <div class="bg-red-500 rounded-full text-white p-1 px-2 text-xs self-center my-auto">
        {{conversation.new_messages_count}}
      </div>
    </div>
  </div>
</template>

<script>
import {
  SparklesIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'ConversationItem',
  components: {
    SparklesIcon,
  },
  props: {
    conversation: Object,
    active: Object,
    selected: Object,
  },
  computed: {
    date() {
      if (!this.conversation) return 'No date';
      return this.conversation.formatted_last_interaction;
    },
    isSelected() {
      return this.selected.includes(this.conversation.uuid);
    },
    source() {
      if (this.conversation) {
        let url = '';
        switch (this.conversation.form_provider) {
          case 'facebook-messenger':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'instagram-messenger':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },
  },
  methods: {
    checkConversation() {
      this.$emit('check-clicked', this.conversation);
    },
    selectConversation() {
      this.$emit('selected-conversation', this.conversation.uuid);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
