<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            Cargando tus empresas...
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'calendar'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'leads'" @changed-company="changedCompany"/>
        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>
      <div class="flex w-full flex-grow h-80">
        <template v-if="isLoadingEvents">
          <div class="w-full h-full bg-header flex items-center justify-center">
            <div class="flex flex-col">
              <div class="w-full flex justify-center">
                <img alt="loading" src="@/assets/loading.gif" class="w-20">
              </div>
              <p class="text-lg text-white text-center object-none object-center ">
                Cargando tus eventos...
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col w-full">
            <div class="w-full flex justify-end">
              <button class="bg-gray-400 h-10 flex rounded-lg m-1 mr-3 p-2 text-white"
                      @click="close()">
                <XIcon class="mx-1 h-5 w-5 text-white" aria-hidden="true"/>
                Cerrar
              </button>
            </div>
            <FullCalendar class='p-3 pt-1 flex-grow' :options='calendarOptions'>
              <template v-slot:eventContent='arg'>
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
              </template>
            </FullCalendar>
          </div>
          <!--detalle calendario-->
          <ModalBox :showing="showEventDetailModal" @close="showEventDetailModal = false">
            <div class="flex">
              <p class="text-black break-words"><b>{{ selectedEvent.lbData.title }}</b></p>
            </div>
            <div class="flex-grow p-1 w-full">

              <div class="flex text-md justify-between m-1 break-words">
                <div><b>Nombre:</b> {{ selectedEvent.lbData.lead_name }}</div>
              </div>
              <div class="flex text-md justify-between m-1 break-words">
                <div><b>Teléfono:</b> {{ selectedEvent.lbData.lead_phone }}</div>
              </div>
              <div class="flex text-md justify-between m-1 break-words">
                <div><b>Inicio:</b> {{ selectedEvent.lbData.start_date }}</div>
              </div>
              <div class="flex text-md justify-between m-1 break-words">
                <div><b>Fin:</b> {{ selectedEvent.lbData.end_date }}</div>
              </div>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showEventDetailModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="closeEventDetailModal">Ver lead
              </button>
            </div>
          </ModalBox>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DropDown from '@/components/DropDown.vue';
import MainDropDown from '@/components/MainDropDown.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import ModalBox from '@/components/ModalBox.vue';
import axios from '@/http/axios';
import {
  XIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'LeadboxCalendar',
  components: {
    FullCalendar,
    DropDown,
    MainDropDown,
    CompanyDropDown,
    // eslint-disable-next-line vue/no-unused-components
    dayGridPlugin,
    // eslint-disable-next-line vue/no-unused-components
    timeGridPlugin,
    // eslint-disable-next-line vue/no-unused-components
    interactionPlugin,
    ModalBox,
    XIcon,
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      // this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
    this.calendarOptions.events = this.calendarEvents;
  },
  data() {
    return {
      selectedEvent: null,
      showEventDetailModal: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay',
        },
        initialView: 'timeGridWeek',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        slotMinTime: '08:00:00',
        slotMaxTime: '22:00:00',
        slotDuration: '00:30:00', // Intervalos de 15 minutos
        slotLabelInterval: '01:00:00', // Etiquetas cada 1 hora
        allDaySlot: false,
        nowIndicator: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: (info) => {
          console.log('Date selected:', info);
          // Tu lógica aquí para manejar la selección de fecha
        },
        eventClick: (info) => {
          console.log('Event clicked:', info.event);
          // Tu lógica aquí para manejar el click en el evento
          this.selectedEvent = info.event.extendedProps;
          this.showEventDetailModal = true;
        },
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
        datesSet: (dateInfo) => {
          // console.log('dateInfo:', dateInfo);
          // console.log('Fecha inicial visible:', dateInfo.start);
          // console.log('Fecha final visible:', dateInfo.end);

          // Compara las fechas utilizando getTime() para comparar el valor de tiempo
          const startChanged = !this.startCalendarDate
            || dateInfo.start.getTime() !== this.startCalendarDate.getTime();
          const endChanged = !this.endCalendarDate
            || dateInfo.end.getTime() !== this.endCalendarDate.getTime();

          // console.log('startChanged:', startChanged);
          // console.log('endChanged:', endChanged);

          if (startChanged || endChanged) {
            // Actualiza las fechas almacenadas
            this.startCalendarDate = new Date(dateInfo.start);
            this.endCalendarDate = new Date(dateInfo.end);

            // Llama a la función para obtener los datos (solicitar eventos)
            if (this.activeCompany) {
              this.getEvents();
            }
          }
        },
      },
      isLoadingCompanies: false,
      isLoadingEvents: false,
      currentEvents: [],
      startCalendarDate: new Date(),
      endCalendarDate: new Date(),
    };
  },
  methods: {
    close() {
      this.$router.push('/leads').catch(() => {
      });
    },
    closeEventDetailModal() {
      // eslint-disable-next-line
      this.showEventDetailModal = false;
      console.log('this.selectedEvent', this.selectedEvent);
      this.goToLead(this.selectedEvent.lbData.lead_uuid);
    },
    goToLead(uuid) {
      // this.$router.push(`/leads?lead=${uuid}`).catch(() => {
      // });
      const url = this.$router.resolve({ path: '/leads', query: { lead: uuid } }).href;
      window.open(url, '_blank');
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      console.log('selectedCompany', selectedCompany);
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      console.log('company', company);
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getEvents();
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },

    changedCompany() {
      this.getEvents();
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      const title = 'titulo';
      const calendarApi = selectInfo.view.calendar;
      calendarApi.unselect(); // clear date selection
      if (title) {
        calendarApi.addEvent({
          id: '465465',
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo);
    },
    handleEvents(events) {
      this.currentEvents = events;
      this.initialEvents = events;
      console.log('events', events);
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
          // this.getEvents();
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    getEvents() {
      // TODO: send pagination
      this.isLoadingEvents = true;
      const payload = {
        company: this.activeCompany.uuid,
        start: this.startCalendarDate,
        end: this.endCalendarDate,
      };
      this.$store.dispatch('fullEvents', payload)
        .then((response) => {
          console.log('respuesta events', response);
          this.calendarOptions.events = response.data.success.map((event) => ({
            id: event.id,
            uuid: event.uuid,
            title: event.title,
            start: event.start_date,
            end: event.end_date,
            color: event.color,
            backgroundColor: event.color,
            textColor: '#ffffff',
            lbData: event,
          }));
          this.isLoadingEvents = false;
          console.log('this.calendarOptions.events', this.calendarOptions.events);
        }, (error) => {
          console.log('error events', error);
          this.calendarOptions.events = [];
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
          this.isLoadingEvents = false;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          this.calendarOptions.events = [];
          this.isLoadingEvents = false;
          console.log('error events', error);
        });
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    companies() {
      return this.$store.state.companies;
    },
    events() {
      return this.$store.state.events;
    },
  },
};
</script>

<style scoped>

</style>
